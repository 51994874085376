// @flow

import * as React from 'react';
import { withRouter, Link } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import validate from 'validate.js';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Grid,
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';

// Component styles
import styles from './styles';

// Form validation schema
import schema from './schema';

import {
  signIn,
  requiredResetPassword,
  type AWSCognitoAuthChallenge
} from 'services/auth';
import { withUserState, type WithUserStateProps } from 'hooks/useUserState';

type State = {
  values: {
    email: string,
    password: string,
    newPassword: string,
    confirmPassword: string
  },
  touched: {
    email: boolean,
    password: boolean,
    newPassword: boolean,
    confirmPassword: boolean
  },
  errors: {
    email: string[] | null,
    password: string[] | null,
    newPassword: string[] | null,
    confirmPassword: string[] | null
  },
  isValid: boolean,
  isLoading: boolean,
  submitError: Error | null,
  awsCognitoAuthChallenge: AWSCognitoAuthChallenge | null
};

const defaultState = {
  values: {
    email: '',
    password: '',
    newPassword: '',
    confirmPassword: ''
  },
  touched: {
    email: false,
    password: false,
    newPassword: false,
    confirmPassword: false
  },
  errors: {
    email: null,
    password: null,
    newPassword: null,
    confirmPassword: null
  },
  isValid: false,
  isLoading: false,
  submitError: null,
  awsCognitoAuthChallenge: null
};

class SignIn extends React.Component<
  WithHistoryProps & WithStylesProps & WithUserStateProps,
  State
> {
  state = defaultState;

  redirectToApp = async () => {
    const { history, userState } = this.props;

    await userState.refresh();

    // Successful sign in, redirect to the dashboard
    history.replace('/');
  };

  validateForm = () => {
    const { values, awsCognitoAuthChallenge } = this.state;

    const newState: State = { ...this.state };

    newState.errors = {
      email: null,
      password: null,
      newPassword: null,
      confirmPassword: null
    };

    if (!awsCognitoAuthChallenge) {
      const errors = validate(values, schema, { format: 'grouped' });
      if (errors) {
        if (errors.email) {
          newState.errors.email = errors.email;
        }
        if (errors.password) {
          newState.errors.password = errors.password;
        }
      }
      newState.isValid = !newState.errors.email && !newState.errors.password;
    } else {
      const newPasswordErrors = [];
      if (!/[a-z]+/.test(values.newPassword)) {
        newPasswordErrors.push('must contain a lowercase letter');
      }
      if (!/[A-Z]+/.test(values.newPassword)) {
        newPasswordErrors.push('must contain an uppercase letter');
      }
      if (!/\d+/.test(values.newPassword)) {
        newPasswordErrors.push('must contain a number');
      }
      if (!/[\^$*.[\]{}()?|\-"!@#%&/,><':;|_~`]+/.test(values.newPassword)) {
        newPasswordErrors.push('must contain a special character');
      }
      if (values.newPassword.length < 8) {
        newPasswordErrors.push('must have at least 8 characters');
      }

      if (newPasswordErrors.length) {
        newState.errors.newPassword = newPasswordErrors;
      }

      if (values.confirmPassword !== values.newPassword) {
        newState.errors.confirmPassword = ['Passwords do not match'];
      }

      newState.isValid =
        !newState.errors.newPassword && !newState.errors.confirmPassword;
    }

    this.setState(newState);
  };

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleSignIn = async e => {
    e.preventDefault();
    try {
      const { values } = this.state;

      this.setState({
        isLoading: true,
        submitError: null
      });

      const result = await signIn(values.email, values.password);
      if (result) {
        this.setState({
          ...defaultState,
          values,
          awsCognitoAuthChallenge: result
        });
        return;
      }

      await this.redirectToApp();
    } catch (error) {
      this.setState({
        isLoading: false,
        submitError: error
      });
    }
  };

  handleResetPassword = async e => {
    e.preventDefault();

    try {
      const { values, awsCognitoAuthChallenge } = this.state;

      if (!awsCognitoAuthChallenge) {
        return;
      }

      this.setState({
        isLoading: true,
        submitError: null
      });

      await requiredResetPassword(
        values.email,
        values.newPassword,
        awsCognitoAuthChallenge
      );

      await this.redirectToApp();
    } catch (error) {
      this.setState({
        isLoading: false,
        submitError: error
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { awsCognitoAuthChallenge } = this.state;

    return (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.quoteWrapper} item lg={5}>
            <div className={classes.quote}>
              <div className={classes.quoteInner} />
            </div>
          </Grid>
          <Grid className={classes.content} item lg={7} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentBody}>
                {awsCognitoAuthChallenge
                  ? this.renderResetPasswordForm()
                  : this.renderSignInForm()}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  renderSignInForm() {
    const { classes } = this.props;
    const {
      values,
      touched,
      errors,
      isValid,
      submitError,
      isLoading
    } = this.state;
    return (
      <form className={classes.form} onSubmit={this.handleSignIn}>
        <img
          alt="VerifyNow logo"
          className={classes.logoImage}
          src="/images/logos/verify-now-logo-stacked.png"
        />
        <Typography className={classes.title} gutterBottom variant="h2">
          Sign In
        </Typography>
        <Typography variant="body2">
          Enter your email (case sensitive) and password below to sign in.
        </Typography>

        <div className={classes.fields}>
          <TextField
            id="sign-in-email"
            fullWidth
            className={classes.textField}
            label="Email Address"
            name="email"
            onChange={event =>
              this.handleFieldChange('email', event.target.value)
            }
            type="text"
            value={values.email}
            variant="outlined"
          />
          {touched.email && errors.email && errors.email.length && (
            <Typography className={classes.fieldError} variant="body2">
              {errors.email[0]}
            </Typography>
          )}
          <TextField
            id="sign-in-confirm-password"
            fullWidth
            className={classes.textField}
            label="Password"
            name="password"
            onChange={event =>
              this.handleFieldChange('password', event.target.value)
            }
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Typography className={classes.signIn} variant="body1">
            Forgot your password?{' '}
            <Link className={classes.signUpUrl} to="/reset-password">
              Click here
            </Link>
          </Typography>
          {touched.password && errors.password && errors.password.length && (
            <Typography className={classes.fieldError} variant="body2">
              {errors.password[0]}
            </Typography>
          )}
        </div>
        {submitError && (
          <Typography className={classes.submitError} variant="body2">
            {submitError.message}
          </Typography>
        )}
        {isLoading ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <Button
            type="submit"
            className={classes.signInButton}
            color="primary"
            disabled={!isValid}
            size="large"
            variant="contained">
            Sign In
          </Button>
        )}
      </form>
    );
  }

  renderResetPasswordForm() {
    const { classes } = this.props;
    const {
      values,
      touched,
      errors,
      isValid,
      submitError,
      isLoading
    } = this.state;

    return (
      <form className={classes.form} onSubmit={this.handleResetPassword}>
        <img
          alt="VerifyNow logo"
          className={classes.logoImage}
          src="/images/logos/verify-now-logo-stacked.png"
        />
        <Typography className={classes.title} gutterBottom variant="h2">
          Password Reset Required
        </Typography>
        <Typography variant="body2">
          The password you entered was a temporary password, and must be reset
          for security purposes. Please enter your new password below to
          continue.
        </Typography>

        <div className={classes.fields}>
          <TextField
            autoFocus
            id="sign-in-new-password"
            fullWidth
            className={classes.textField}
            label="New Password"
            name="newPassword"
            onChange={event =>
              this.handleFieldChange('newPassword', event.target.value)
            }
            type="password"
            value={values.newPassword}
            variant="outlined"
          />
          {touched.newPassword &&
            errors.newPassword &&
            errors.newPassword.length && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.newPassword[0]}
              </Typography>
            )}
          <TextField
            id="sign-in-password"
            fullWidth
            className={classes.textField}
            label="Confirm Password"
            name="confirmPassword"
            onChange={event =>
              this.handleFieldChange('confirmPassword', event.target.value)
            }
            type="password"
            value={values.confirmPassword}
            variant="outlined"
          />
          {touched.confirmPassword &&
            errors.confirmPassword &&
            errors.confirmPassword.length && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.confirmPassword[0]}
              </Typography>
            )}
        </div>
        {submitError && (
          <Typography className={classes.submitError} variant="body2">
            {submitError.message}
          </Typography>
        )}
        {isLoading ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <Button
            type="submit"
            className={classes.signInButton}
            color="primary"
            disabled={!isValid}
            size="large"
            variant="contained">
            Reset Password &amp; Sign In
          </Button>
        )}
      </form>
    );
  }
}

SignIn.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  withUserState,
  withRouter,
  withStyles(styles)
)(SignIn);
