// @flow

import * as React from 'react';

import SuccessSnackbar from 'components/Snackbars/SuccessSnackbar';
import ErrorSnackbar from 'components/Snackbars/ErrorSnackbar';
import LoadingButton from 'components/LoadingButton';
import { Tooltip } from '@material-ui/core';

import { resendEmail } from 'services/applications';

type Props = {
  application: Application,
  onSent: Application => void
};

const ResendEmailButton = ({ application, onSent }: Props) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);

  const handleSend = React.useCallback(async () => {
    setLoading(true);
    try {
      const newApplication = await resendEmail(application);
      console.log({
        newApplication,
        application
      });
      onSent(newApplication);
      setSuccessMessage('Original email resent.');
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  }, [application, onSent]);

  return (
    <>
      <Tooltip
        placement="left"
        title="This will resend the email that was sent to the applicant when the application was created">
        <span>
          <LoadingButton
            color="primary"
            onClick={handleSend}
            loading={loading}
            variant="outlined">
            Resend Original
          </LoadingButton>
        </span>
      </Tooltip>
      <SuccessSnackbar
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}>
        {successMessage}
      </SuccessSnackbar>
      <ErrorSnackbar open={!!error} onClose={() => setError(null)}>
        {error && error.message}
      </ErrorSnackbar>
    </>
  );
};

export default React.memo<Props>(ResendEmailButton);
