// @flow

import * as React from 'react';

// Externals
import moment from 'moment';

// Material components
import { TableRow, TableCell, TableSortLabel, Grid } from '@material-ui/core';

// Shared services
import {
  createNpcVeroExtractRecordUrl,
  getNpcVeroExtractRecords,
  getNpcVeroExtractRecordFiles
} from 'services/npc-vero-extract-records';
// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared components
import { TableData } from 'components';

import DownloadExtractButton from '../NpcExtractRecords/DownloadExtractButton';
import useTableStyles from 'components/TableData/styles';
import ExtractRecordStatusChip from 'components/ExtractRecordStatusChip';

const defaultSort = {
  orderingByProperty: 'updatedAt',
  isOrderingAscending: false
};

export default function NpcVeroExtractRecords() {
  const tableClasses = useTableStyles();

  return (
    <DashboardLayout title="AFP Vero Batches">
      <TableData
        addLabelText="AFP Vero Batch"
        defaultSort={defaultSort}
        createFormUrl={createNpcVeroExtractRecordUrl}
        getData={getNpcVeroExtractRecords}
        filterFn={(batchRecord, filter) =>
          batchRecord.status.toLowerCase().includes(filter.toLowerCase())
        }
        noDataMessage="There are no AFP Vero Batches"
        renderHeaderCells={(
          orderingByProperty,
          isOrderingAscending,
          onHeaderClick
        ) => (
          <TableRow>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'updatedAt'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('updatedAt')}>
                Date Updated
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">Date Range</TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'numberOfApplications'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('numberOfApplications')}>
                No. of Submissions
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'status'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('status')}>
                Status
              </TableSortLabel>
            </TableCell>
            <TableCell align="left" />
          </TableRow>
        )}
        renderDataCells={npcVeroExtractRecord => (
          <TableRow key={npcVeroExtractRecord.id}>
            <TableCell>
              {moment(npcVeroExtractRecord.updatedAt).format('LLL')}
            </TableCell>
            <TableCell>
              {!!npcVeroExtractRecord.submission
                ? `${moment(
                    npcVeroExtractRecord.submission.dateTimeFrom
                  ).format(
                    'LLL'
                    // $FlowFixMe Flow sucks
                  )} - ${moment(
                    npcVeroExtractRecord.submission.dateTimeTo
                  ).format('LLL')}`
                : '-'}
            </TableCell>
            <TableCell>{npcVeroExtractRecord.numberOfSubmissions}</TableCell>
            <TableCell>
              <ExtractRecordStatusChip status={npcVeroExtractRecord.status} />
            </TableCell>
            <TableCell className={tableClasses.alignCellRight}>
              {npcVeroExtractRecord.status === 'COMPLETE' && (
                <Grid container spacing={1} wrap="nowrap">
                  <Grid item>
                    <DownloadExtractButton
                      id={npcVeroExtractRecord.id}
                      getFiles={getNpcVeroExtractRecordFiles}
                      disabled={!npcVeroExtractRecord.numberOfSubmissions}
                    />
                  </Grid>
                </Grid>
              )}
            </TableCell>
          </TableRow>
        )}
      />
    </DashboardLayout>
  );
}
