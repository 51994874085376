// @flow

import withAuth from './withAuth';

export const apiUrl = process.env.REACT_APP_VN_API_URL || '';

const headers: Headers = new Headers();
headers.append('Content-Type', 'application/json');
headers.append('Accept', 'application/json');

export async function getRequestAPI<T>(path: string): Promise<T> {
  const opts = {
    headers,
    mode: 'cors',
    cache: 'default',
    method: 'GET'
  };

  let url = `${apiUrl}${path}`;

  return await withAuth(fetch)(url, opts);
}

export async function postRequestAPI<T, OutT>(
  path: string,
  resource: T
): Promise<OutT> {
  const opts = {
    headers,
    mode: 'cors',
    cache: 'default',
    method: 'POST',
    body: JSON.stringify(resource)
  };

  const url = `${apiUrl}${path}`;

  return await withAuth(fetch)(url, opts);
}
