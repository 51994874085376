// @flow

import * as React from 'react';

// Externals
import moment from 'moment';

// Material components
import { TableRow, TableCell, TableSortLabel, Grid } from '@material-ui/core';

// Shared services
import { getAccounts, createCreateAccountUrl } from 'services/accounts';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared components
import { TableData, AccountUsersButton } from 'components';

import useTableStyles from 'components/TableData/styles';

function AccountsList() {
  const tableStyles = useTableStyles();
  return (
    <DashboardLayout title="Accounts">
      <TableData
        addLabelText="Account"
        createFormUrl={createCreateAccountUrl}
        getData={getAccounts}
        filterFn={(account, filter) =>
          account.name.toLowerCase().includes(filter.toLowerCase())
        }
        noDataMessage="There are no accounts"
        renderHeaderCells={(
          orderingByProperty,
          isOrderingAscending,
          onHeaderClick
        ) => (
          <TableRow>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'name'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('name')}>
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'description'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('description')}>
                Description
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'createdAt'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('createdAt')}>
                Registered
              </TableSortLabel>
            </TableCell>
            <TableCell align="left" />
          </TableRow>
        )}
        renderDataCells={account => (
          <TableRow key={account.id}>
            <TableCell>{account.name}</TableCell>
            <TableCell>{account.description}</TableCell>
            <TableCell>{moment(account.createdAt).format('LLL')}</TableCell>

            <TableCell className={tableStyles.alignCellRight}>
              <Grid container spacing={1} wrap="nowrap">
                <Grid item>
                  <AccountUsersButton accountId={account.id} />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        )}
      />
    </DashboardLayout>
  );
}

export default AccountsList;
