// @flow

import contentDisposition from 'content-disposition';

import { fileDownloadWithAuth } from './withAuth';
const apiUrl = process.env.REACT_APP_VERIFY_NOW_API_URL || '';

export const fileDownloadGetRequest = async (
  path: string,
  typeToAccept: string
): Promise<{ blob: Blob, filename?: string }> => {
  const headers: Headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', typeToAccept);
  const opts = {
    headers,
    mode: 'cors',
    cache: 'default',
    method: 'GET'
  };

  let url = `${apiUrl}${path}`;
  const response /* : Response */ = await fileDownloadWithAuth(fetch)(
    url,
    opts
  );
  const blob = await response.blob();

  let filename = undefined;
  const contentDispositionHeader = response.headers.get('Content-Disposition');
  if (contentDispositionHeader) {
    try {
      console.log(
        'Attempting to parse "Content-Disposition" response header',
        contentDispositionHeader
      );
      const result = contentDisposition.parse(contentDispositionHeader);
      console.log(
        'Result from parsing "Content-Disposition" response header',
        result
      );
      filename = result.parameters.filename;
    } catch (error) {
      console.warn(
        'Error while parsing "Content-Disposition" response header',
        error
      );
    }
  }

  return {
    blob,
    filename
  };
};
