// @flow

import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from 'services/crud-factory';

import { getAccounts } from 'services/accounts';

export async function getClients(): Promise<VerifyNowClient[]> {
  const [{ clients }, accounts] = await Promise.all([
    getRequest('/clients'),
    getAccounts()
  ]);

  clients.forEach(
    client =>
      (client.account = accounts.find(
        account => account.id === client.accountId
      ))
  );
  return clients;
}

export async function createCreateClientUrl(): Promise<string> {
  const { formUrl } = await postRequest('/clients/create-url');
  return formUrl;
}

export async function createEditClientUrl(clientId: number): Promise<string> {
  const { formUrl } = await postRequest(`/clients/${clientId}/edit-url`);
  return formUrl;
}

export async function getClientUsers(
  clientId: number
): Promise<VerifyNowClientUser[]> {
  const { users } = await getRequest(`/clients/${clientId}/users`);
  return users;
}

export async function addUserToClient(
  clientId: number,
  userId: string
): Promise<{ username: string }> {
  return await putRequest(`/clients/${clientId}/users/${userId.trim()}`);
}

export async function removeUserFromClient(
  clientId: number,
  userId: string
): Promise<void> {
  await deleteRequest(`/clients/${clientId}/users/${userId}`);
}
