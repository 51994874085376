// @flow

import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from 'services/crud-factory';

export async function getAccounts(): Promise<VerifyNowAccount[]> {
  const { accounts } = await getRequest('/accounts');
  return accounts;
}

export async function createCreateAccountUrl(): Promise<string> {
  const { formUrl } = await postRequest('/accounts/create-url');
  return formUrl;
}

export async function getAccountUsers(
  accountId: number
): Promise<VerifyNowAccountUser[]> {
  const { users } = await getRequest(`/accounts/${accountId}/users`);
  return users;
}

export async function addUserToAccount(
  accountId: number,
  userId: string
): Promise<{ username: string }> {
  return await putRequest(`/accounts/${accountId}/users/${userId.trim()}`);
}

export async function removeUserFromAccount(
  accountId: number,
  userId: string
): Promise<void> {
  await deleteRequest(`/accounts/${accountId}/users/${userId}`);
}
