// @flow

import * as React from 'react';

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Grid,
  Typography,
  IconButton
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Delete as DeleteIcon } from '@material-ui/icons';
import ErrorSnackbar from 'components/Snackbars/ErrorSnackbar';
import SuccessSnackbar from 'components/Snackbars/SuccessSnackbar';
import LoadingButton from 'components/LoadingButton';
import { deleteApplicationStatusNote } from 'services/applications';

type Props = {
  application: Application,
  onUpdated: Application => void
};

function CancelApplicationButton({ application, onUpdated }: Props) {
  const [isShowingConfirmation, setIsShowingConfirmation] = React.useState(
    false
  );
  const [isSuccessOpen, setIsSuccessOpen] = React.useState(false);

  const [isConfirming, setIsConfirming] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleConfirm = React.useCallback(async () => {
    setIsConfirming(true);
    setError(null);

    try {
      const updatedApplication = await deleteApplicationStatusNote(application);
      setIsShowingConfirmation(false);
      onUpdated(updatedApplication);
      setIsSuccessOpen(true);
    } catch (err) {
      setError(err);
    }

    setIsConfirming(false);
  }, [application, onUpdated]);

  return (
    <>
      <IconButton
        color="inherit"
        onClick={() => setIsShowingConfirmation(true)}>
        <DeleteIcon />
      </IconButton>

      <Dialog
        open={isShowingConfirmation}
        onClose={() => setIsShowingConfirmation(false)}
        onExiting={() => {
          setError(null);
        }}>
        <DialogTitle disableTypography>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography variant="h4">
                Remove Application Status Note
              </Typography>
            </Grid>
            <Grid item xs={false}>
              <IconButton onClick={() => setIsShowingConfirmation(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you would like to remove the applications status note?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="outlined"
            color="primary"
            loading={isConfirming}
            onClick={handleConfirm}>
            Yes, remove notes
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={() => setIsSuccessOpen(false)}>
        Application successfully cancelled
      </SuccessSnackbar>

      <ErrorSnackbar open={!!error} onClose={() => setError(null)}>
        <span>{error && error.message}</span>
      </ErrorSnackbar>
    </>
  );
}

export default React.memo<Props>(CancelApplicationButton);
