// @flow

import * as React from 'react';
import LoadingButton from 'components/LoadingButton';
import ErrorSnackbar from 'components/Snackbars/ErrorSnackbar';
import { downloadAndSaveXml } from 'services/acic-batches';
type Props = {
  acicBatchId: number
};

const DownloadXmlButton = ({ acicBatchId }: Props) => {
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<null | Error>(null);
  const handleDownload = React.useCallback(async () => {
    try {
      setIsDownloading(true);
      await downloadAndSaveXml(acicBatchId);
    } catch (err) {
      setError(err);
    }
    setIsDownloading(false);
  }, [acicBatchId]);
  return (
    <>
      <LoadingButton
        size="small"
        variant="outlined"
        loading={isDownloading}
        onClick={handleDownload}>
        Download XML
      </LoadingButton>

      <ErrorSnackbar open={!!error} onClose={() => setError(null)}>
        <span>{error && error.message}</span>
      </ErrorSnackbar>
    </>
  );
};

export default React.memo<Props>(DownloadXmlButton);
