// @flow

import * as React from 'react';

// Externals
import moment from 'moment';

// Material components
import { TableRow, TableCell, TableSortLabel, Grid } from '@material-ui/core';

// Shared services
import {
  createBlueCardExtractRecordUrl,
  getBlueCardExtractRecords,
  getBlueCardExtractRecordFiles
} from 'services/blue-card-extract-records';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared components
import { TableData } from 'components';

import DownloadExtractButton from '../NpcExtractRecords/DownloadExtractButton';
import useTableStyles from 'components/TableData/styles';
import ExtractRecordStatusChip from 'components/ExtractRecordStatusChip';

const defaultSort = {
  orderingByProperty: 'updatedAt',
  isOrderingAscending: false
};

export default function BlueCardExtractRecords() {
  const tableClasses = useTableStyles();
  return (
    <DashboardLayout title="Blue Card Batches">
      <TableData
        defaultSort={defaultSort}
        addLabelText="Blue Card Batch"
        createFormUrl={createBlueCardExtractRecordUrl}
        getData={getBlueCardExtractRecords}
        filterFn={(batchRecord, filter) =>
          batchRecord.status.toLowerCase().includes(filter.toLowerCase())
        }
        noDataMessage="There are no Blue Card Batches"
        renderHeaderCells={(
          orderingByProperty,
          isOrderingAscending,
          onHeaderClick
        ) => (
          <TableRow>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'updatedAt'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('updatedAt')}>
                Date Updated
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">Date Range</TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'numberOfApplications'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('numberOfApplications')}>
                No. of Submissions
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'status'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('status')}>
                Status
              </TableSortLabel>
            </TableCell>
            <TableCell align="left" />
          </TableRow>
        )}
        renderDataCells={npcExtractRecord => (
          <TableRow key={npcExtractRecord.id}>
            <TableCell>
              {moment(npcExtractRecord.updatedAt).format('LLL')}
            </TableCell>
            <TableCell>
              {!!npcExtractRecord.submission
                ? `${moment(npcExtractRecord.submission.dateTimeFrom).format(
                    'LLL'
                    // $FlowFixMe Flow sucks
                  )} - ${moment(npcExtractRecord.submission.dateTimeTo).format(
                    'LLL'
                  )}`
                : '-'}
            </TableCell>
            <TableCell>{npcExtractRecord.numberOfSubmissions}</TableCell>
            <TableCell>
              <ExtractRecordStatusChip status={npcExtractRecord.status} />
            </TableCell>
            <TableCell className={tableClasses.alignCellRight}>
              {npcExtractRecord.status === 'COMPLETE' && (
                <Grid container spacing={1} wrap="nowrap">
                  <Grid item>
                    <DownloadExtractButton
                      id={npcExtractRecord.id}
                      getFiles={getBlueCardExtractRecordFiles}
                      disabled={!npcExtractRecord.numberOfSubmissions}
                    />
                  </Grid>
                </Grid>
              )}
            </TableCell>
          </TableRow>
        )}
      />
    </DashboardLayout>
  );
}
