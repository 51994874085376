// @flow
import * as React from 'react';
import {
  Box,
  Typography,
  Collapse,
  Button,
  makeStyles
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Info, NotificationImportant, Email, Remove } from '@material-ui/icons';
import { useSelectApplications } from 'hooks/useSelectApplications';
import SuccessSnackbar from 'components/Snackbars/SuccessSnackbar';
import ErrorSnackbar from 'components/Snackbars/ErrorSnackbar';
import LoadingButton from 'components/LoadingButton';

import {
  sendApplicantReminderEmails,
  sendRefereeReminderEmails
} from 'services/applications';

type Props<T> = {
  filteredData: T[],
  onRefresh: () => void
};

const useStyles = makeStyles(theme => {
  return {
    alert: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.border,
      borderWidth: 1,
      borderStyle: 'solid'
    },
    deselectButton: {
      color: theme.palette.text.secondary
    },
    actionButton: {
      marginLeft: theme.spacing(1)
    }
  };
});

function SelectedPanel<T: { id: number }>({
  filteredData,
  onRefresh
}: Props<T>) {
  const classes = useStyles();
  const [remindingApplicants, setRemindingApplicants] = React.useState<boolean>(
    false
  );
  const [remindingReferees, setRemindingReferees] = React.useState<boolean>(
    false
  );
  const [successMessage, setSuccessMessage] = React.useState<string | null>(
    null
  );
  const [error, setError] = React.useState<Error | null>(null);
  const resetMessages = React.useCallback(() => {
    setError(null);
    setSuccessMessage(null);
  }, []);

  const {
    selectedApplicationIds,
    selectedOutsideFilteredCount,
    deselectAllApplicationIds
  } = useSelectApplications(filteredData);

  const handleSendApplicantsReminder = React.useCallback(async () => {
    resetMessages();
    setRemindingApplicants(true);
    try {
      const { total, sent } = await sendApplicantReminderEmails(
        selectedApplicationIds
      );
      onRefresh();
      setSuccessMessage(
        `${sent}/${total} applicants have been successfully reminded.`
      );
    } catch (err) {
      setError(err);
    }
    setRemindingApplicants(false);
  }, [onRefresh, selectedApplicationIds, resetMessages]);

  const handleSendRefereesReminder = React.useCallback(async () => {
    resetMessages();
    setRemindingReferees(true);
    try {
      const { total, sent } = await sendRefereeReminderEmails(
        selectedApplicationIds
      );
      onRefresh();
      setSuccessMessage(
        `${sent}/${total} applications have had there referees successfully reminded.`
      );
    } catch (err) {
      setError(err);
    }
    setRemindingReferees(false);
  }, [onRefresh, selectedApplicationIds, resetMessages]);

  const buttonsDisabled =
    !selectedApplicationIds.length || remindingApplicants || remindingReferees;
  return (
    <Collapse in={!!selectedApplicationIds.length}>
      <Box mb={2}>
        <Alert
          icon={<Info color="disabled" />}
          className={classes.alert}
          action={
            <>
              <Button
                startIcon={<Remove />}
                className={classes.deselectButton}
                size="small"
                onClick={deselectAllApplicationIds}>
                Deselect All
              </Button>
              <LoadingButton
                color="primary"
                disabled={buttonsDisabled}
                startIcon={<NotificationImportant />}
                className={classes.actionButton}
                size="small"
                loading={remindingApplicants}
                onClick={handleSendApplicantsReminder}>
                Send Applicant Reminder
              </LoadingButton>
              <LoadingButton
                color="primary"
                disabled={buttonsDisabled}
                startIcon={<Email />}
                className={classes.actionButton}
                size="small"
                loading={remindingReferees}
                onClick={handleSendRefereesReminder}>
                Send Referee Reminder
              </LoadingButton>
            </>
          }>
          <Typography variant="body1">
            <b>{selectedApplicationIds.length}</b> applications selected
            {!!selectedOutsideFilteredCount && (
              <span>{` (${selectedOutsideFilteredCount} not shown)`}</span>
            )}
            .
          </Typography>
        </Alert>
        <SuccessSnackbar
          open={!!successMessage}
          onClose={() => setSuccessMessage(null)}>
          {successMessage}
        </SuccessSnackbar>
        <ErrorSnackbar open={!!error} onClose={() => setError(null)}>
          <span>{error && error.message}</span>
        </ErrorSnackbar>
      </Box>
    </Collapse>
  );
}

export default SelectedPanel;
