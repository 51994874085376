// @flow

import { getRequest, postRequest } from 'services/crud-factory';

export async function getNpcVeroExtractRecords(): Promise<ExtractZipRecord[]> {
  const { npcVeroExtractRecords } = await getRequest<{
    npcVeroExtractRecords: ExtractZipRecord[]
  }>('/npc-vero-extract-records');
  return npcVeroExtractRecords.map(npcVeroExtractRecord => ({
    ...npcVeroExtractRecord,
    numberOfSubmissions: npcVeroExtractRecord.submissionIds.length
  }));
}

export async function createNpcVeroExtractRecordUrl(): Promise<string> {
  const { formUrl } = await postRequest('/npc-vero-extract-records/create-url');
  return formUrl;
}

export async function getNpcVeroExtractRecordFiles(
  id: number
): Promise<
  Array<{
    fileName: string,
    url: string
  }>
> {
  const { files } = await postRequest(
    `/npc-vero-extract-records/${id}/zip-file`
  );
  return files;
}
