// @flow

import * as React from 'react';
import AWS from 'aws-sdk';

import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Grid,
  Typography,
  IconButton,
  Box
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import ErrorSnackbar from 'components/Snackbars/ErrorSnackbar';
import SuccessSnackbar from 'components/Snackbars/SuccessSnackbar';
import LoadingButton from 'components/LoadingButton';
import { updateRequestURL } from 'services/api-applications';

type Props = {
  request: Request,
  onUploaded: Request => void
};

function UploadResultButton({ request, onUploaded }: Props) {

  const [isShowingConfirmation, setIsShowingConfirmation] = React.useState(
    false
  );
  const [isSuccessOpen, setIsSuccessOpen] = React.useState(false);

  const [isConfirming, setIsConfirming] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleConfirm = React.useCallback(async () => {
    setIsConfirming(true);
    setError(null);

    try {
      let file = document.getElementById('result-' + request._id);
      let s3 = new AWS.S3();
      if(!file.files  || !file.files.length > 0){
        setError({message: 'No files selected for upload'});
        setIsConfirming(false);
        return;
      }

      const params = {
        Bucket: process.env.REACT_APP_VN_BUCKET,
        Key: 'RESULT-' + request.internalTrackingID + '.pdf',
        Body: file.files[0],
        ContentType: 'application/pdf'
      };
      await s3.upload(params, async function(err, data) {
        if (err) {
          setError(err);
          setIsConfirming(false);
        } else {
          const postdata = {
            resultURL: data.Location,
            _id: request._id
          };
          const updatedRequest = await updateRequestURL(postdata);
          onUploaded(updatedRequest);
          setIsShowingConfirmation(false);
          setIsSuccessOpen(true);
        }
      });
    } catch (err) {
      setError(err);
    }

    setIsConfirming(false);
  }, [request, onUploaded]);

  return (
    <>
      <Button variant="outlined" onClick={() => setIsShowingConfirmation(true)}>
        Upload Result
      </Button>

      <Dialog
        open={isShowingConfirmation}
        onClose={() => setIsShowingConfirmation(false)}
        >
        <DialogTitle disableTypography>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography variant="h4">Upload Result</Typography>
            </Grid>
            <Grid item xs={false}>
              <IconButton onClick={() => setIsShowingConfirmation(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Box paddingTop={1} paddingBottom={2}>
            <DialogContentText>
              Select a document to upload
            </DialogContentText>
            <input type="file" id={'result-' + request._id}/>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="outlined"
            color="primary"
            loading={isConfirming}
            onClick={handleConfirm}>
            Upload
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={() => setIsSuccessOpen(false)}>
        Document Successfully Uploaded
      </SuccessSnackbar>

      <ErrorSnackbar open={!!error} onClose={() => setError(null)}>
        <span>{error && error.message}</span>
      </ErrorSnackbar>
    </>
  );
}

export default React.memo<Props>(UploadResultButton);
