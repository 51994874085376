// @flow

import * as React from 'react';

// Material components
import { makeStyles, Chip } from '@material-ui/core';

import { verificationTypeStatuses } from 'services/verification-types';

// Component styles
const useStyles = makeStyles(theme => ({
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.error.contrastText
  },
  errorOutlined: {
    borderColor: theme.palette.error.dark,
    color: theme.palette.error.dark
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText
  }
}));

type Props = {
  status: VerificationStatus
};

function VerificationTypeStatusChip({ status }: Props) {
  const classes = useStyles();
  const chipProps = {
    clickable: false,
    color: 'default',
    label: '',
    size: 'small',
    variant: 'outlined',
    classes: {}
  };

  const verificationTypeStatus = verificationTypeStatuses.find(
    verificationTypeStatus => verificationTypeStatus.id === status
  );
  if (verificationTypeStatus) {
    chipProps.label = verificationTypeStatus.name;
  } else {
    chipProps.label = 'Unknown';
  }

  switch (status) {
    case 'NOT_STARTED': {
      break;
    }
    case 'IN_PROGRESS': {
      chipProps.color = 'primary';
      break;
    }
    case 'CLARIFICATION_REQUIRED': {
      chipProps.variant = 'default';
      chipProps.classes.root = classes.error;
      break;
    }
    case 'COMPLETE': {
      chipProps.variant = 'default';
      chipProps.classes.root = classes.success;
      break;
    }
    default: {
      chipProps.variant = 'default';
      chipProps.classes.root = classes.warning;
    }
  }

  return <Chip {...chipProps} />;
}

export default React.memo<Props>(VerificationTypeStatusChip);
