// @flow

import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.error.contrastText
  },
  errorOutlined: {
    borderColor: theme.palette.error.dark,
    color: theme.palette.error.dark
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  successOutlined: {
    borderColor: theme.palette.success.dark,
    color: theme.palette.success.dark
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText
  }
}));
