// @flow

import * as React from 'react';
import { withRouter } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Badge,
  IconButton,
  Popover,
  Toolbar,
  Typography,
  Tooltip
} from '@material-ui/core';

// Material icons
import {
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
  NotificationsOutlined as NotificationsIcon,
  Input as InputIcon
} from '@material-ui/icons';

// Shared services
import { getNotifications } from '../../../../services/notification';

// Custom components
import { NotificationList } from './components';

// Component styles
import styles from './styles';
import { signOut } from 'services/auth';

type Props = {
  className: string,
  title: string,
  isSidebarOpen: boolean,
  onToggleSidebar: () => void
};
type State = {
  notifications: {}[],
  notificationsLimit: number,
  notificationsCount: number,
  notificationsEl: HTMLElement | null
};

class Topbar extends React.Component<
  Props & WithStylesProps & WithHistoryProps,
  State
> {
  signal = true;

  static defaultProps = {
    onToggleSidebar: () => {}
  };

  state = {
    notifications: [],
    notificationsLimit: 4,
    notificationsCount: 0,
    notificationsEl: null
  };

  async getNotifications() {
    try {
      const { notificationsLimit } = this.state;

      const { notifications, notificationsCount } = await getNotifications(
        notificationsLimit
      );

      if (this.signal) {
        this.setState({
          notifications,
          notificationsCount
        });
      }
    } catch (error) {
      return;
    }
  }

  componentDidMount() {
    this.signal = true;
    this.getNotifications();
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleSignOut = async () => {
    this.props.history.replace('/sign-in');
    await signOut();
  };

  handleShowNotifications = event => {
    this.setState({
      notificationsEl: event.currentTarget
    });
  };

  handleCloseNotifications = () => {
    this.setState({
      notificationsEl: null
    });
  };

  render() {
    const {
      classes,
      className,
      title,
      isSidebarOpen,
      onToggleSidebar
    } = this.props;
    const { notifications, notificationsCount, notificationsEl } = this.state;

    const rootClassName = classNames(classes.root, className);
    const showNotifications = Boolean(notificationsEl);

    return (
      <React.Fragment>
        <div className={rootClassName}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              className={classes.menuButton}
              onClick={onToggleSidebar}
              variant="text">
              {isSidebarOpen ? <ArrowBackIcon /> : <MenuIcon />}
            </IconButton>
            <Typography className={classes.title} variant="h4">
              {title}
            </Typography>
            <Typography className={classes.environment} variant="h4">
              {process.env.REACT_APP_VERIFY_NOW_ENVRIONMENT || ''}
            </Typography>
            <Tooltip title="Notifications" placement="bottom">
              <IconButton
                className={classes.notificationsButton}
                onClick={this.handleShowNotifications}>
                <Badge
                  badgeContent={notificationsCount}
                  color="primary"
                  variant="dot">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Sign Out" placement="bottom">
              <IconButton
                className={classes.signOutButton}
                onClick={this.handleSignOut}>
                <InputIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </div>
        <Popover
          anchorEl={notificationsEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          onClose={this.handleCloseNotifications}
          open={showNotifications}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}>
          <NotificationList
            notifications={notifications}
            onSelect={this.handleCloseNotifications}
          />
        </Popover>
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(Topbar);
