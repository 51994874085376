// @flow

import * as React from 'react';
import { Chip } from '@material-ui/core';
import useStyles from 'components/ApplicationStatusChip/styles';

type Props = {
  status: ExtractRecordStatus
};

function ExtractRecordStatusChip({ status }: Props) {
  const classes = useStyles();
  const chipClass = React.useMemo(() => {
    switch (status) {
      case 'COMPLETE':
        return classes.success;
      case 'FAILED':
        return classes.error;
      default:
        return;
    }
  }, [classes.error, classes.success, status]);
  return (
    <Chip
      label={status}
      size="small"
      color={status === 'PROCESSING' ? 'primary' : 'default'}
      className={chipClass}
      variant={status === 'PROCESSING' ? 'outlined' : 'default'}
    />
  );
}

export default React.memo<Props>(ExtractRecordStatusChip);
