// @flow

import * as React from 'react';
import { withRouter } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import validate from 'validate.js';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Grid,
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';

// Component styles
import styles from './styles';

// Form validation schema
import schema from './schema';

import { startResetPassword, resetPassword } from 'services/auth';

type State = {
  hasSentCode: boolean,
  values: {
    email: string,
    code: string,
    newPassword: string,
    confirmPassword: string
  },
  touched: {
    email: boolean,
    code: boolean,
    newPassword: boolean,
    confirmPassword: boolean
  },
  errors: {
    email: string[] | null,
    code: string[] | null,
    newPassword: string[] | null,
    confirmPassword: string[] | null
  },
  isValid: boolean,
  isLoading: boolean,
  submitError: Error | null
};

const defaultState = {
  hasSentCode: false,
  values: {
    email: '',
    code: '',
    newPassword: '',
    confirmPassword: ''
  },
  touched: {
    email: false,
    code: false,
    newPassword: false,
    confirmPassword: false
  },
  errors: {
    email: null,
    code: null,
    newPassword: null,
    confirmPassword: null
  },
  isValid: false,
  isLoading: false,
  submitError: null,
  awsCognitoAuthChallenge: null
};

class ResetPassword extends React.Component<
  WithHistoryProps & WithStylesProps,
  State
> {
  state = defaultState;

  validateForm = () => {
    const { values, hasSentCode } = this.state;

    const newState: State = { ...this.state };

    newState.errors = {
      email: null,
      code: null,
      newPassword: null,
      confirmPassword: null
    };

    const errors = validate(values, schema, { format: 'grouped' });

    if (hasSentCode) {
      const newPasswordErrors = [];
      if (!/[a-z]+/.test(values.newPassword)) {
        newPasswordErrors.push('must contain a lowercase letter');
      }
      if (!/[A-Z]+/.test(values.newPassword)) {
        newPasswordErrors.push('must contain an uppercase letter');
      }
      if (!/\d+/.test(values.newPassword)) {
        newPasswordErrors.push('must contain a number');
      }
      if (!/[\^$*.[\]{}()?|\-"!@#%&/,><':;|_~`]+/.test(values.newPassword)) {
        newPasswordErrors.push('must contain a special character');
      }
      if (values.newPassword.length < 8) {
        newPasswordErrors.push('must have at least 8 characters');
      }

      if (newPasswordErrors.length) {
        newState.errors.newPassword = newPasswordErrors;
      }

      if (values.confirmPassword !== values.newPassword) {
        newState.errors.confirmPassword = ['Passwords do not match'];
      }
      if (errors && errors.code) {
        newState.errors.code = errors.code;
      }
    } else {
      if (errors && errors.email) {
        newState.errors.email = errors.email;
      }
    }

    newState.isValid =
      !newState.errors.newPassword &&
      !newState.errors.confirmPassword &&
      !newState.errors.email &&
      !newState.errors.code;

    this.setState(newState);
  };

  handleFieldChange = (field, value) => {
    const newState: State = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleStartResetPassword = async e => {
    e.preventDefault();

    try {
      const { values } = this.state;

      this.setState({
        isLoading: true,
        submitError: null
      });

      await startResetPassword(values.email);

      this.setState({
        isLoading: false,
        hasSentCode: true
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        submitError: error
      });
    }
  };

  handleResetPassword = async e => {
    e.preventDefault();

    try {
      const { history } = this.props;
      const { values } = this.state;

      this.setState({
        isLoading: true,
        submitError: null
      });

      await resetPassword(values.email, values.newPassword, values.code);
      // Successful password reset, redirect to the sign in screen
      history.replace('/sign-in');
    } catch (error) {
      this.setState({
        isLoading: false,
        submitError: error
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { hasSentCode } = this.state;

    return (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.quoteWrapper} item lg={5}>
            <div className={classes.quote}>
              <div className={classes.quoteInner} />
            </div>
          </Grid>
          <Grid className={classes.content} item lg={7} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentBody}>
                {hasSentCode
                  ? this.renderResetPasswordForm()
                  : this.renderEmailForm()}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  renderEmailForm() {
    const { classes } = this.props;
    const {
      values,
      touched,
      errors,
      isValid,
      submitError,
      isLoading
    } = this.state;

    return (
      <form className={classes.form} onSubmit={this.handleStartResetPassword}>
        <img
          alt="VerifyNow logo"
          className={classes.logoImage}
          src="/images/logos/verify-now-logo-stacked.png"
        />
        <Typography className={classes.title} gutterBottom variant="h2">
          Reset Password Request
        </Typography>
        <Typography variant="body2">
          Enter your email address to receive a verification code to reset your
          password.
        </Typography>

        <div className={classes.fields}>
          <TextField
            id="reset-password-email"
            fullWidth
            className={classes.textField}
            label="Email Address"
            name="email"
            onChange={event =>
              this.handleFieldChange('email', event.target.value)
            }
            type="text"
            value={values.email}
            variant="outlined"
          />
          {touched.email && errors.email && errors.email.length && (
            <Typography className={classes.fieldError} variant="body2">
              {errors.email[0]}
            </Typography>
          )}
        </div>
        {submitError && (
          <Typography className={classes.submitError} variant="body2">
            {submitError.message}
          </Typography>
        )}
        {isLoading ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <Button
            type="submit"
            className={classes.signInButton}
            color="primary"
            disabled={!isValid}
            size="large"
            variant="contained">
            Request Verification Code
          </Button>
        )}
      </form>
    );
  }

  renderResetPasswordForm() {
    const { classes } = this.props;
    const {
      values,
      touched,
      errors,
      isValid,
      submitError,
      isLoading
    } = this.state;

    return (
      <form className={classes.form} onSubmit={this.handleResetPassword}>
        <img
          alt="VerifyNow logo"
          className={classes.logoImage}
          src="/images/logos/verify-now-logo-stacked.png"
        />
        <Typography className={classes.title} gutterBottom variant="h2">
          Reset Password
        </Typography>
        <Typography variant="body2">
          You have been emailed a verification code, please enter it below with
          your new password.
        </Typography>

        <div className={classes.fields}>
          <TextField
            autoFocus
            id="reset-password-code"
            fullWidth
            className={classes.textField}
            label="Verification Code"
            name="code"
            onChange={event =>
              this.handleFieldChange('code', event.target.value)
            }
            type="text"
            value={values.code}
            variant="outlined"
          />
          {touched.code && errors.code && errors.code.length && (
            <Typography className={classes.fieldError} variant="body2">
              {errors.code[0]}
            </Typography>
          )}
          <TextField
            id="reset-password-new-password"
            fullWidth
            className={classes.textField}
            label="New Password"
            name="newPassword"
            onChange={event =>
              this.handleFieldChange('newPassword', event.target.value)
            }
            type="password"
            value={values.newPassword}
            variant="outlined"
          />
          {touched.newPassword &&
            errors.newPassword &&
            errors.newPassword.length && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.newPassword[0]}
              </Typography>
            )}
          <TextField
            id="reset-password-confirm-password"
            fullWidth
            className={classes.textField}
            label="Confirm Password"
            name="confirmPassword"
            onChange={event =>
              this.handleFieldChange('confirmPassword', event.target.value)
            }
            type="password"
            value={values.confirmPassword}
            variant="outlined"
          />
          {touched.confirmPassword &&
            errors.confirmPassword &&
            errors.confirmPassword.length && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.confirmPassword[0]}
              </Typography>
            )}
        </div>
        {submitError && (
          <Typography className={classes.submitError} variant="body2">
            {submitError.message}
          </Typography>
        )}
        {isLoading ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <Button
            type="submit"
            className={classes.signInButton}
            color="primary"
            disabled={!isValid}
            size="large"
            variant="contained">
            Reset Password
          </Button>
        )}
      </form>
    );
  }
}

ResetPassword.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(ResetPassword);
