// @flow

import * as React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import {
  AccountCircle,
  Close,
  Delete,
  DeleteForever
} from '@material-ui/icons';

import { removeUserFromClient } from 'services/clients';

type Props = {
  clientId: number,
  user: VerifyNowClientUser,
  onDelete?: (user: VerifyNowClientUser) => void,
  setError: (error: Error | null) => void
};

function ClientUser({ clientId, user, onDelete, setError }: Props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const clearAnchorEl = React.useCallback(() => setAnchorEl(null), []);

  const handleDeleteConfirm = React.useCallback(async () => {
    setAnchorEl(null);
    setError(null);
    setIsDeleting(true);
    try {
      await removeUserFromClient(clientId, user.username);
      onDelete && onDelete(user);
    } catch (err) {
      console.warn('An error occurred while deleting a user', err);
      setError(err);
      setIsDeleting(false);
    }
  }, [clientId, onDelete, setError, user]);
  return (
    <>
      <ListItem disableGutters>
        <ListItemIcon>
          <AccountCircle fontSize="large" color="disabled" />
        </ListItemIcon>
        <ListItemText>{user.username}</ListItemText>
        {onDelete && (
          <ListItemSecondaryAction>
            {isDeleting ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton
                edge="end"
                onClick={e => setAnchorEl(e.currentTarget)}>
                <Delete />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        )}
      </ListItem>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={clearAnchorEl}>
        <MenuItem onClick={clearAnchorEl} dense>
          <ListItemIcon>
            <Close />
          </ListItemIcon>
          <ListItemText>Cancel</ListItemText>
        </MenuItem>{' '}
        <MenuItem onClick={handleDeleteConfirm} dense>
          <ListItemIcon>
            <DeleteForever color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export default React.memo<Props>(ClientUser);
