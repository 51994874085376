import AWS from 'aws-sdk';

const DownloadResults = async (request) => {
  if(!request.resultURL){
    alert("No Result URL found");
    return;
  }
  let s3 = new AWS.S3();

  const results = {"document": "RESULT", "key": "RESULT-" + request.internalTrackingID + ".pdf", "extension": ".pdf"};

  const params = {
    Bucket: process.env.REACT_APP_VN_BUCKET,
    Key: results.key
  };

  return await s3.getObject(params, function(err, data) {
    if (err) {
      console.log(err, err.stack);
      return err;
    } else {
      let document = new Blob([data.Body]);
      var url = window.URL.createObjectURL(document);
      const fileName = request.internalTrackingID + "-" + request.applicantName + "-" + results.document + results.extension;
      downloadURL(url, fileName);
      return data;
    }
  })
};

function downloadURL(url, name) {
  var link = document.createElement("a");
  link.download = name;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default DownloadResults;
