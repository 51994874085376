// @flow

import * as React from 'react';

// Material components
import { makeStyles, Chip } from '@material-ui/core';

import { applicationStatuses } from 'services/api-applications';

// Component styles
const useStyles = makeStyles(theme => ({
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.error.contrastText
  },
  errorOutlined: {
    borderColor: theme.palette.error.dark,
    color: theme.palette.error.dark
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  successOutlined: {
    borderColor: theme.palette.success.dark,
    color: theme.palette.success.dark
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText
  }
}));

type Props = {
  status: ApplicationStatus
};

function APIApplicationStatusChip({ status }: Props) {
  const classes = useStyles();
  const chipProps = {
    clickable: false,
    color: 'default',
    label: '',
    size: 'small',
    variant: 'outlined',
    classes: {}
  };

  const applicationStatus = applicationStatuses.find(
    applicationStatus => applicationStatus.id === status
  );
  if (applicationStatus) {
    chipProps.label = applicationStatus.name;
  } else {
    chipProps.label = 'Unknown';
  }

  switch (status) {
    case 'CLARIFICATION_REQUIRED': {
      chipProps.classes.root = classes.errorOutlined;
      break;
    }
    case 'APPLICANT_SUBMITTED': {
      chipProps.color = 'primary';
      chipProps.variant = 'default';
      break;
    }
    case 'VERIFICATION_IN_PROGRESS': {
      chipProps.color = 'primary';
      break;
    }
    case 'QA_PENDING': {
      chipProps.classes.root = classes.successOutlined;
      break;
    }
    case 'VERIFICATION_COMPLETE': {
      chipProps.variant = 'default';
      chipProps.classes.root = classes.success;
      break;
    }
    case 'PENDING_COMPLETE_NO_RESULT': {
      chipProps.classes.root = classes.successOutlined;
      break;
    }
    case 'VERIFICATION_COMPLETE_NO_RESULT': {
      chipProps.variant = 'default';
      chipProps.classes.root = classes.success;
      break;
    }
    case 'CANCELLED': {
      chipProps.classes.root = classes.warning;
      break;
    }
    default: {
      chipProps.variant = 'default';
      chipProps.classes.root = classes.warning;
    }
  }

  return <Chip {...chipProps} />;
}

export default React.memo<Props>(APIApplicationStatusChip);
