// @flow

import * as React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button, TextField, Typography } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

import { ErrorSnackbar, SuccessSnackbar } from 'components/Snackbars';
import { UnorderedList, ListItemWithIcon } from 'components/Lists';

import { changePassword } from 'services/auth';

// Component styles
import styles from './styles';

type Props = {
  className?: string
};

function Password({ classes, className, ...rest }: Props & WithStylesProps) {
  const [existingPassword, setExistingPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [isSaveable, setIsSaveable] = React.useState(false);
  const [hasSaved, setHasSaved] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [successfullySaved, setSuccessfullySaved] = React.useState(false);
  const [savingError, setSavingError] = React.useState(null);

  const hasNotConfirmedPassword =
    !!newPassword && !!confirmPassword && newPassword !== confirmPassword;
  const hasNoNumber = !/\d+/.test(newPassword);
  const hasNoLowercase = !/[a-z]+/.test(newPassword);
  const hasNoUppercase = !/[A-Z]+/.test(newPassword);
  const hasLessThanEightCharacters = newPassword.length < 8;
  const hasNoSpecialCharacter = !/[\^$*.[\]{}()?|\-"!@#%&/,><':;|_~`]+/.test(
    newPassword
  );

  const rootClassName = classNames(classes.root, className);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={async e => {
        e.preventDefault();

        setHasSaved(true);

        if (
          hasNotConfirmedPassword ||
          hasNoNumber ||
          hasNoLowercase ||
          hasNoUppercase ||
          hasNoSpecialCharacter ||
          hasLessThanEightCharacters
        ) {
          return;
        }
        setSavingError(null);
        setIsSaving(true);

        try {
          await changePassword(existingPassword, newPassword);

          setHasSaved(false);
          setSuccessfullySaved(true);
          setExistingPassword('');
          setNewPassword('');
          setConfirmPassword('');
          setIsSaveable(false);
          setIsSaving(false);
        } catch (error) {
          console.error('Error while changing password', error);
          setSavingError(error);
          setIsSaving(false);
        }
      }}>
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader>
          <PortletLabel subtitle="Update password" title="Password" />
        </PortletHeader>
        <PortletContent>
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            name="existingPassword"
            label="Current Password"
            type="password"
            value={existingPassword}
            required
            onChange={event => {
              setExistingPassword(event.target.value);
              if (!isSaveable) {
                setIsSaveable(true);
              }
            }}
            error={hasSaved && !existingPassword}
            helperText={
              hasSaved && !existingPassword
                ? 'Current Password is required'
                : undefined
            }
          />
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            name="newPassword"
            label="New Password"
            type="password"
            value={newPassword}
            required
            onChange={event => {
              setNewPassword(event.target.value);
              if (!isSaveable) {
                setIsSaveable(true);
              }
            }}
            error={hasSaved && !newPassword}
            helperText={
              hasSaved && !newPassword ? 'New Password is required' : undefined
            }
          />
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            required
            onChange={event => {
              setConfirmPassword(event.target.value);
              if (!isSaveable) {
                setIsSaveable(true);
              }
            }}
            error={hasNotConfirmedPassword}
            helperText={
              hasNotConfirmedPassword ? 'Passwords do not match' : undefined
            }
          />

          {!!newPassword ? (
            <React.Fragment>
              <Typography>
                Passwords must contain at least one of each of the following:
              </Typography>
              <UnorderedList>
                <ListItemWithIcon error={hasLessThanEightCharacters}>
                  8 characters
                </ListItemWithIcon>
                <ListItemWithIcon error={hasNoNumber}>
                  A number
                </ListItemWithIcon>
                <ListItemWithIcon error={hasNoLowercase}>
                  A lowercase letter
                </ListItemWithIcon>
                <ListItemWithIcon error={hasNoUppercase}>
                  An uppercase letter
                </ListItemWithIcon>
                <ListItemWithIcon error={hasNoSpecialCharacter}>
                  A special character i.e.{' '}
                  <b>
                    {
                      '^ $ * . [ ] { } ( ) ? - " ! @ # % & / , > < \' : ; | _ ~ `'
                    }
                  </b>
                </ListItemWithIcon>
              </UnorderedList>
            </React.Fragment>
          ) : null}
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            disabled={isSaving}>
            Update
          </Button>
          <Button
            type="button"
            variant="outlined"
            disabled={(!isSaveable && !hasSaved) || isSaving}
            onClick={() => {
              setHasSaved(false);
              setExistingPassword('');
              setNewPassword('');
              setConfirmPassword('');
              setIsSaveable(false);
            }}
            className={classes.cancelButton}>
            Cancel
          </Button>
        </PortletFooter>
      </Portlet>

      <SuccessSnackbar
        open={successfullySaved}
        onClose={() => setSuccessfullySaved(false)}>
        Password has been successfully changed.
      </SuccessSnackbar>

      <ErrorSnackbar open={!!savingError} onClose={() => setSavingError(null)}>
        <span>{savingError && savingError.message}</span>
      </ErrorSnackbar>
    </form>
  );
}

Password.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Password);
