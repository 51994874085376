// @flow

import * as React from 'react';

// Externals
import useLocalstorage from '@rooks/use-localstorage';

// Material components
import {
  makeStyles,
  TableRow,
  TableCell,
  TableSortLabel,
  Typography,
  Checkbox
} from '@material-ui/core';

// Shared services
import {
  getApplications,
  applicationStatuses,
  generateCreateApplicationUrl
} from 'services/applications';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared components

import ApplicationTableRow from './ApplicationTableRow';
import { TableData } from 'components';

// Hooks
import useCanManageClients from 'hooks/useCanManageClients';
import useIsAdministrator from 'hooks/useIsAdministrator';
import { SelectApplicationsContextProvider } from 'hooks/useSelectApplications';

import SelectAllCheckbox from './SelectAllCheckbox';
import SelectedPanel from './SelectedPanel';

import useTableStyles from 'components/TableData/styles';
import { getStatusDisplayName } from 'components/ApplicationStatusChip';

const defaultSort = {
  orderingByProperty: 'createdAt',
  isOrderingAscending: false
};

const useStyles = makeStyles(theme => ({
  field: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1.5),
    display: 'flex',
    alignItems: 'center',
    '&:first-child': {
      marginTop: 0
    }
  }
}));

function ApplicationsList() {
  const defaultStatusFilter = {
    NEW: true,
    CLARIFICATION_REQUIRED: true,
    CHALLENGE_FAILED: true,
    APPLICANT_IN_PROGRESS: true,
    APPLICANT_SUBMITTED: true,
    VERIFICATION_IN_PROGRESS: true,
    QA_PENDING: true,
    VERIFICATION_COMPLETE: true,
    CANCELLED: true
  };
  const tableClasses = useTableStyles();
  let [statusFilter, setStatusFilter] = useLocalstorage(
    'APPLICATIONS_STATUS_FILTER',
    defaultStatusFilter
  );

  if (!statusFilter) {
    statusFilter = defaultStatusFilter;
  }

  const classes = useStyles();
  const canManageClients = useCanManageClients();
  const isAdministrator = useIsAdministrator();

  const getData = React.useCallback(() => getApplications(isAdministrator), [
    isAdministrator
  ]);

  const allStatusesSelected = applicationStatuses.every(
    status => !!statusFilter[status.id]
  );
  const someStatusesSelected =
    !allStatusesSelected &&
    applicationStatuses.some(status => !!statusFilter[status.id]);

  const handleStatusHeaderClick = React.useCallback(
    ({ status }) => getStatusDisplayName(status),
    []
  );

  return (
    <DashboardLayout title="Applications">
      <SelectApplicationsContextProvider>
        <TableData
          getData={getData}
          filterFn={(application, filter) => {
            const lowerCaseFilter = filter.toLowerCase();
            return (
              statusFilter[application.status] &&
              (application.applicantDetails.firstName
                .toLowerCase()
                .includes(lowerCaseFilter) ||
                application.applicantDetails.surname
                  .toLowerCase()
                  .includes(lowerCaseFilter) ||
                application.client.name
                  .toLowerCase()
                  .includes(lowerCaseFilter) ||
                application.referenceNumber
                  .toLowerCase()
                  .includes(lowerCaseFilter) ||
                application.applicationNumber
                  .toLowerCase()
                  .includes(lowerCaseFilter) ||
                application.applicantDetails.email
                  .toLowerCase()
                  .includes(lowerCaseFilter))
            );
          }}
          noDataMessage="There are no applications that match your filters"
          getId={application => application.id}
          advancedFilterComponent={
            <React.Fragment>
              <div className={classes.field}>
                <Checkbox
                  color="primary"
                  checked={allStatusesSelected}
                  indeterminate={someStatusesSelected}
                  onChange={() => {
                    setStatusFilter(
                      allStatusesSelected ? {} : { ...defaultStatusFilter }
                    );
                  }}
                />
                <Typography variant="h5">Filter by Status</Typography>
              </div>
              {applicationStatuses.map(status => (
                <div className={classes.field} key={status.id}>
                  <Checkbox
                    color="primary"
                    checked={!!statusFilter[status.id]}
                    onChange={event =>
                      setStatusFilter({
                        ...statusFilter,
                        // $FlowFixMe
                        [status.id]: event.target.checked
                      })
                    }
                  />
                  <div>
                    <Typography variant="body1">{status.name}</Typography>
                    <Typography variant="caption">
                      {status.description}
                    </Typography>
                  </div>
                </div>
              ))}
            </React.Fragment>
          }
          addLabelText="Applicant"
          createFormUrl={
            canManageClients ? generateCreateApplicationUrl : undefined
          }
          renderAboveTableDisplay={({ filteredData, onRefresh }) => (
            <SelectedPanel filteredData={filteredData} onRefresh={onRefresh} />
          )}
          renderHeaderCells={(
            orderingByProperty,
            isOrderingAscending,
            onHeaderClick,
            filteredData
          ) => (
            <TableRow>
              <TableCell>
                <SelectAllCheckbox filteredData={filteredData} />
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderingByProperty === 'applicationNumber'}
                  direction={isOrderingAscending ? 'asc' : 'desc'}
                  onClick={() => onHeaderClick('applicationNumber')}>
                  Application Number
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderingByProperty === 'client.name'}
                  direction={isOrderingAscending ? 'asc' : 'desc'}
                  onClick={() => onHeaderClick('client.name')}>
                  Client
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderingByProperty === 'applicantDetails.surname'}
                  direction={isOrderingAscending ? 'asc' : 'desc'}
                  onClick={() => onHeaderClick('applicantDetails.surname')}>
                  Applicant
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderingByProperty === 'referenceNumber'}
                  direction={isOrderingAscending ? 'asc' : 'desc'}
                  onClick={() => onHeaderClick('referenceNumber')}>
                  Reference Number
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderingByProperty === 'createdAt'}
                  direction={isOrderingAscending ? 'asc' : 'desc'}
                  onClick={() => onHeaderClick('createdAt')}>
                  Date Registered
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderingByProperty === 'applicantSubmissionTimestamp'}
                  direction={isOrderingAscending ? 'asc' : 'desc'}
                  onClick={() => onHeaderClick('applicantSubmissionTimestamp')}>
                  Applicant Submission Date
                </TableSortLabel>
              </TableCell>
              {isAdministrator && (
                <TableCell className={tableClasses.alignCellCenter}>
                  GreenId Status
                </TableCell>
              )}
              <TableCell className={tableClasses.alignCellRight}>
                <TableSortLabel
                  active={orderingByProperty === 'status'}
                  direction={isOrderingAscending ? 'asc' : 'desc'}
                  onClick={() => onHeaderClick(handleStatusHeaderClick)}>
                  Status
                </TableSortLabel>
              </TableCell>
            </TableRow>
          )}
          renderDataCells={(application, onChange) => (
            <ApplicationTableRow
              key={application.id}
              application={application}
              onChange={onChange}
            />
          )}
          defaultSort={defaultSort}
        />
      </SelectApplicationsContextProvider>
    </DashboardLayout>
  );
}

export default ApplicationsList;
