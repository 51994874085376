// @flow

export const white = '#FFFFFF';

export const grey = {
  '50': '#FAFAFA',
  '100': '#F5F5F5',
  '200': '#EEEEEE',
  '300': '#E0E0E0',
  '400': '#BDBDBD',
  '500': '#9E9E9E',
  '600': '#757575',
  '700': '#616161',
  '800': '#424242',
  '900': '#212121'
};

export const black = '#000000';

export const blue = '#1978b6';

export const green = '#45B880';

export const orange = '#FFB822';

export const red = '#ED4740';

export const primary = {
  contrastText: white,
  main: blue,
  light: '#E3EFF6',
  dark: '#08488E'
};
