// @flow

import * as React from 'react';
import {
  CircularProgress,
  TextField,
  IconButton,
  InputAdornment
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

type Props = React$ElementProps<TextField> & {
  validationError?: string,
  loading: boolean,
  value?: mixed,
  onSubmit: () => mixed
};

const InputFieldRecordEntry = ({
  loading,
  validationError,
  onSubmit,
  ...props
}: Props) => {
  const handleSubmit = React.useCallback(
    e => {
      e.preventDefault();
      if (!validationError && !loading) onSubmit();
    },
    [loading, onSubmit, validationError]
  );
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        error={!!validationError}
        helperText={validationError}
        InputProps={{
          endAdornment: loading ? (
            <CircularProgress size={20} />
          ) : (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                type="submit"
                disabled={loading || !!validationError || !props.value}>
                <Add />
              </IconButton>
            </InputAdornment>
          )
        }}
        {...props}
      />
    </form>
  );
};

export default React.memo<Props>(InputFieldRecordEntry);
