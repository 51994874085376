// @flow

import AWS from 'aws-sdk';
import jsonwebtoken from 'jsonwebtoken';

type AWSAuthenticationResult = {
  AccessToken: string,
  ExpiresIn: number,
  IdToken: string,
  TokenType: string,
  RefreshToken?: string
};
export type AWSCognitoAuthChallenge = {
  ChallengeName: string,
  Session: string,
  ChallengeParameters: { [key: string]: string },
  AuthenticationResult: ?AWSAuthenticationResult
};

const ClientId = process.env.REACT_APP_COGNITO_CLIENT_ID || '';

const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
  region: 'ap-southeast-2'
});

export const RequiresSignInErrorName = 'RequiresSignInError';
const RequiresSignInError = new Error('You must sign in to use this service');
RequiresSignInError.name = RequiresSignInErrorName;
export const RequiresPasswordResetErrorName = 'RequiresPasswordResetError';
const RequiresPasswordResetError = new Error(
  'You must reset your password to continue'
);
RequiresPasswordResetError.name = RequiresPasswordResetErrorName;

const EXPIRES_AT = `COGNITO_${ClientId}_EXPIRES_AT`;
const ACCESS_TOKEN = `COGNITO_${ClientId}_ACCESS_TOKEN`;
const ID_TOKEN = `COGNITO_${ClientId}_ID_TOKEN`;
const REFRESH_TOKEN = `COGNITO_${ClientId}_REFRESH_TOKEN`;

const unsignedAWSRequest = async request => {
  request.removeListener(
    'validate',
    AWS.EventListeners.Core.VALIDATE_CREDENTIALS
  );
  request.removeListener('sign', AWS.EventListeners.Core.SIGN);
  return request.promise();
};

const storeAuthenticationResult = (
  authenticationResult: AWSAuthenticationResult
) => {
  // Take off 5 seconds to ensure a request does not become unauthenticated mid request
  const expiresAt = authenticationResult.ExpiresIn * 1000 + Date.now() - 5000;
  localStorage.setItem(EXPIRES_AT, expiresAt.toString());
  localStorage.setItem(ACCESS_TOKEN, authenticationResult.AccessToken);
  localStorage.setItem(ID_TOKEN, authenticationResult.IdToken);
  if (authenticationResult.RefreshToken) {
    localStorage.setItem(REFRESH_TOKEN, authenticationResult.RefreshToken);
  }
};

export async function signIn(
  username: string,
  password: string
): Promise<AWSCognitoAuthChallenge | void> {
  console.log('Attempting sign using username', username);
  const result = await unsignedAWSRequest(
    cognitoIdentityServiceProvider.initiateAuth({
      AuthFlow: 'USER_PASSWORD_AUTH',
      ClientId,
      AuthParameters: {
        USERNAME: username,
        PASSWORD: password
      }
    })
  );

  if (result.AuthenticationResult) {
    storeAuthenticationResult(result.AuthenticationResult);
  } else if (result.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
    console.log('User requires a password reset', result);
    return result;
  } else {
    throw new Error('Could not authenticate user.');
  }
}

export async function requiredResetPassword(
  username: string,
  password: string,
  challenge: AWSCognitoAuthChallenge
): Promise<void> {
  console.log(
    'Attempting to reset password after being issued a temporary password',
    username
  );
  const result = await unsignedAWSRequest(
    cognitoIdentityServiceProvider.respondToAuthChallenge({
      ChallengeName: challenge.ChallengeName,
      ClientId,
      Session: challenge.Session,
      ChallengeResponses: {
        USERNAME: username,
        NEW_PASSWORD: password
      }
    })
  );

  console.log(
    'Successfully reset password after being issued a temporary password',
    username
  );
  storeAuthenticationResult(result.AuthenticationResult);
}

export async function changePassword(
  existingPassword: string,
  newPassword: string
): Promise<void> {
  console.log('Attempting to change current users password');
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  await unsignedAWSRequest(
    cognitoIdentityServiceProvider.changePassword({
      AccessToken: accessToken,
      PreviousPassword: existingPassword,
      ProposedPassword: newPassword
    })
  );
}

export async function startResetPassword(username: string): Promise<void> {
  console.log('Starting the reset password flow for username', username);
  await unsignedAWSRequest(
    cognitoIdentityServiceProvider.forgotPassword({
      ClientId,
      Username: username
    })
  );
}

export async function resetPassword(
  username: string,
  password: string,
  code: string
): Promise<void> {
  console.log('Resetting the password for username', username);
  await unsignedAWSRequest(
    cognitoIdentityServiceProvider.confirmForgotPassword({
      ClientId,
      ConfirmationCode: code,
      Password: password,
      Username: username
    })
  );

  console.log('Successfully reset the password for username', username);
}

export async function signOut() {
  try {
    console.log('Signing out the current user');
    const accessToken = await getAccessToken();
    if (accessToken) {
      await unsignedAWSRequest(
        cognitoIdentityServiceProvider.globalSignOut({
          AccessToken: accessToken
        })
      );
    }
  } catch (error) {
    if (error.name !== RequiresSignInErrorName) {
      console.warn('Error while attempting to sign out', error);
    }
  }

  localStorage.removeItem(EXPIRES_AT);
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(ID_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
}

export function isSignedIn() {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  return !!refreshToken;
}

function isAuthenticated() {
  const expiresAt = localStorage.getItem(EXPIRES_AT);
  if (!expiresAt) {
    return false;
  }

  const expiresAtInMilliseconds = parseInt(expiresAt, 10);
  if (Number.isNaN(expiresAtInMilliseconds)) {
    return false;
  }

  return expiresAtInMilliseconds > Date.now();
}

async function refreshSession(): Promise<void> {
  if (isAuthenticated()) {
    return;
  }

  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  if (!refreshToken) {
    console.log(
      'Could not refresh the current users session as they do not have refresh token in storage'
    );
    throw RequiresSignInError;
  }
  try {
    console.log('Refreshing the current users session');
    const result = await unsignedAWSRequest(
      cognitoIdentityServiceProvider.initiateAuth({
        AuthFlow: 'REFRESH_TOKEN_AUTH',
        ClientId,
        AuthParameters: {
          REFRESH_TOKEN: refreshToken
        }
      })
    );
    storeAuthenticationResult(result.AuthenticationResult);
    return result.AuthenticationResult;
  } catch (error) {
    console.error('Could not refresh users session', error);
    throw RequiresSignInError;
  }
}

export async function getAccessToken(): Promise<string> {
  await refreshSession();

  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  if (accessToken) {
    return accessToken;
  }

  throw RequiresSignInError;
}

export async function getIdToken(): Promise<string> {
  await refreshSession();

  const idToken = localStorage.getItem(ID_TOKEN);
  if (idToken) {
    return idToken;
  }

  throw RequiresSignInError;
}

export async function getProfile(): Promise<Profile | null> {
  const idToken = await getIdToken();

  const decoded = jsonwebtoken.decode(idToken);
  if (typeof decoded === 'string') {
    return null;
  } else {
    return decoded;
  }
}
