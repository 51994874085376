// @flow

import * as React from 'react';

import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Grid,
  Typography,
  IconButton,
  Box
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import ErrorSnackbar from 'components/Snackbars/ErrorSnackbar';
import SuccessSnackbar from 'components/Snackbars/SuccessSnackbar';
import LoadingButton from 'components/LoadingButton';
import { cancelApplication } from 'services/applications';

type Props = {
  application: Application,
  onCancelled: Application => void
};

function CancelApplicationButton({ application, onCancelled }: Props) {
  const [isShowingConfirmation, setIsShowingConfirmation] = React.useState(
    false
  );
  const [isSuccessOpen, setIsSuccessOpen] = React.useState(false);
  const [cancelledNote, setCancelledNote] = React.useState('');
  const [isDirty, setIsDirty] = React.useState(false);

  const [isConfirming, setIsConfirming] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleConfirm = React.useCallback(async () => {
    setIsConfirming(true);
    setError(null);

    try {
      const updatedApplication = await cancelApplication(
        application,
        cancelledNote
      );
      setIsShowingConfirmation(false);
      onCancelled(updatedApplication);
      setIsSuccessOpen(true);
    } catch (err) {
      setError(err);
    }

    setIsConfirming(false);
  }, [application, cancelledNote, onCancelled]);

  return (
    <>
      <Button variant="outlined" onClick={() => setIsShowingConfirmation(true)}>
        Cancel Application
      </Button>

      <Dialog
        open={isShowingConfirmation}
        onClose={() => setIsShowingConfirmation(false)}
        onEntering={() => {
          setCancelledNote('');
          setIsDirty(false);
        }}>
        <DialogTitle disableTypography>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography variant="h4">Cancel Application</Typography>
            </Grid>
            <Grid item xs={false}>
              <IconButton onClick={() => setIsShowingConfirmation(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Box paddingTop={1} paddingBottom={2}>
            <DialogContentText>
              Are you sure you would like to cancel this application? This
              cannot be undone.
            </DialogContentText>
            <TextField
              InputLabelProps={{
                shrink: true
              }}
              multiline
              rowsMax={4}
              required
              label="Notes"
              value={cancelledNote}
              variant="outlined"
              fullWidth
              onChange={e => {
                setIsDirty(true);
                setCancelledNote(e.target.value);
              }}
              placeholder="Please enter a note to indicate why the application was cancelled"
              error={isDirty && !cancelledNote}
              helperText={
                isDirty && !cancelledNote && 'A cancelled note is required'
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="outlined"
            color="primary"
            loading={isConfirming}
            disabled={!cancelledNote}
            onClick={handleConfirm}>
            Yes, cancel application
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={() => setIsSuccessOpen(false)}>
        Application successfully cancelled
      </SuccessSnackbar>

      <ErrorSnackbar open={!!error} onClose={() => setError(null)}>
        <span>{error && error.message}</span>
      </ErrorSnackbar>
    </>
  );
}

export default React.memo<Props>(CancelApplicationButton);
