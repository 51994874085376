// @flow

import * as React from 'react';

// Externals
import moment from 'moment';

// Material components
import { TableRow, TableCell, TableSortLabel, Grid } from '@material-ui/core';

// Shared services
import {
  getClients,
  createCreateClientUrl,
  createEditClientUrl
} from 'services/clients';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared components
import { TableData } from 'components';
import { ErrorSnackbar } from 'components/Snackbars';
import LoadingButton from 'components/LoadingButton';
import ClientUsersButton from 'components/ClientUsersButton';

import useTableStyles from 'components/TableData/styles';

function ClientList() {
  const tableClasses = useTableStyles();
  const [isCreatingEditUrl, setIsCreatingEditUrl] = React.useState(undefined);
  const [editUrlError, setEditUrlError] = React.useState(null);

  return (
    <DashboardLayout title="Clients">
      <TableData
        addLabelText="Client"
        createFormUrl={createCreateClientUrl}
        editFormUrl={createEditClientUrl}
        getData={getClients}
        filterFn={(client, filter) =>
          client.name.toLowerCase().includes(filter.toLowerCase())
        }
        noDataMessage="There are no clients"
        renderHeaderCells={(
          orderingByProperty,
          isOrderingAscending,
          onHeaderClick
        ) => (
          <TableRow>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'name'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('name')}>
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'description'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('description')}>
                Description
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'officeLabel'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('officeLabel')}>
                Office Label
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'officeCode'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('officeCode')}>
                Office Code
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'account.name'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('account.name')}>
                Account
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'createdAt'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('createdAt')}>
                Registered
              </TableSortLabel>
            </TableCell>
            <TableCell align="left" />
          </TableRow>
        )}
        renderDataCells={client => (
          <TableRow key={client.id}>
            <TableCell>{client.name}</TableCell>
            <TableCell>{client.description}</TableCell>
            <TableCell>{client.officeLabel}</TableCell>
            <TableCell>{client.officeCode}</TableCell>
            <TableCell>
              {client.account ? client.account.name : 'No account'}
            </TableCell>
            <TableCell>{moment(client.createdAt).format('LLL')}</TableCell>
            <TableCell className={tableClasses.alignCellRight}>
              <Grid container spacing={1} wrap="nowrap">
                <Grid item>
                  <ClientUsersButton client={client} />
                </Grid>
                <Grid item>
                  <LoadingButton
                    size="small"
                    variant="outlined"
                    loading={isCreatingEditUrl === client.id}
                    onClick={async () => {
                      try {
                        setEditUrlError(null);
                        setIsCreatingEditUrl(client.id);
                        const formUrl = await createEditClientUrl(client.id);
                        window.location.href = formUrl;
                      } catch (error) {
                        setEditUrlError(error);
                        setIsCreatingEditUrl(undefined);
                      }
                    }}>
                    Edit
                  </LoadingButton>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        )}
      />
      <ErrorSnackbar
        open={!!editUrlError}
        onClose={() => setEditUrlError(null)}>
        <span>{editUrlError && editUrlError.message}</span>
      </ErrorSnackbar>
    </DashboardLayout>
  );
}

export default ClientList;
