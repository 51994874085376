// @flow

import * as React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  Typography
} from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';

import { addUserToClient, getClientUsers } from 'services/clients';
import { ErrorSnackbar } from 'components/Snackbars';
import InputFieldRecordEntry from './InputFieldRecordEntry';
import ClientUser from './ClientUser';
import { getAccountUsers } from 'services/accounts';

interface Props {
  client: VerifyNowClient;
}

function ClientUsersButton({ client: { id: clientId, accountId } }: Props) {
  const [isViewingUsers, setIsViewingUsers] = React.useState(false);

  const [isAddingUser, setIsAddingUser] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [newUser, setNewUser] = React.useState('');
  const [
    { clientUsers, accountUsers, isLoading, loadError },
    setState
  ] = React.useState<{
    clientUsers: Array<VerifyNowClientUser>,
    accountUsers: Array<VerifyNowAccountUser>,
    isLoading: boolean,
    loadError: Error | null
  }>({
    clientUsers: [],
    accountUsers: [],
    isLoading: false,
    loadError: null
  });

  const alreadyExists = React.useMemo(() => {
    return (
      clientUsers.some(user => user.username === newUser) ||
      accountUsers.some(user => user.username === newUser)
    );
  }, [clientUsers, accountUsers, newUser]);

  const handleAddUser = React.useCallback(async () => {
    setIsAddingUser(true);
    setError(null);
    try {
      const { username } = await addUserToClient(clientId, newUser);
      setState(currentState => ({
        ...currentState,
        clientUsers: [
          {
            username,
            role: 'MANAGER'
          },
          ...currentState.clientUsers
        ]
      }));
      setNewUser('');
    } catch (err) {
      console.warn('An error occurred while adding a user', err);
      setError(err);
    }
    setIsAddingUser(false);
  }, [clientId, newUser, setError]);

  const handleNewUserChange = React.useCallback(e => {
    setNewUser(e.target.value);
  }, []);

  const handleDelete = React.useCallback(clientUser => {
    setState(currentState => ({
      ...currentState,
      clientUsers: currentState.clientUsers.filter(
        existingClientUser =>
          existingClientUser.username !== clientUser.username
      )
    }));
  }, []);

  React.useEffect(() => {
    const getUserData = async () => {
      if (!isViewingUsers) {
        setState({
          loadError: null,
          clientUsers: [],
          accountUsers: [],
          isLoading: false
        });
        return;
      }

      setState({
        loadError: null,
        clientUsers: [],
        accountUsers: [],
        isLoading: true
      });

      try {
        const [newClientUsers, newAccountUsers] = await Promise.all([
          getClientUsers(clientId),
          getAccountUsers(accountId)
        ]);
        setState({
          loadError: null,
          clientUsers: newClientUsers,
          accountUsers: newAccountUsers,
          isLoading: false
        });
      } catch (err) {
        console.warn('An error occurred while fetching users', err);
        setState({
          loadError: err,
          clientUsers: [],
          accountUsers: [],
          isLoading: false
        });
      }
    };
    getUserData();
  }, [clientId, isViewingUsers, accountId]);

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        onClick={() => setIsViewingUsers(true)}>
        Users
      </Button>

      <Dialog
        open={isViewingUsers}
        maxWidth="sm"
        fullWidth
        onClose={() => setIsViewingUsers(false)}>
        <DialogTitle disableTypography>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography variant="h4">Client Users</Typography>
            </Grid>
            <Grid item xs={false}>
              <IconButton onClick={() => setIsViewingUsers(false)}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers>
          <InputFieldRecordEntry
            autoFocus
            fullWidth
            label="Add User"
            placeholder="Enter the new user's email address"
            disabled={isAddingUser}
            value={newUser}
            onChange={handleNewUserChange}
            validationError={alreadyExists ? 'User already exists' : undefined}
            loading={isAddingUser}
            onSubmit={handleAddUser}
          />

          {isLoading ? (
            <CircularProgress />
          ) : loadError ? (
            <Box mt={2}>
              <Typography color="error">{loadError.message}</Typography>
            </Box>
          ) : clientUsers.length + accountUsers.length > 0 ? (
            <>
              <List>
                {clientUsers.map(user => (
                  <ClientUser
                    key={user.username}
                    clientId={clientId}
                    user={user}
                    onDelete={handleDelete}
                    setError={setError}
                  />
                ))}
              </List>
              {accountUsers.length > 0 && (
                <>
                  <Typography>Account managers</Typography>
                  <List>
                    {accountUsers.map(user => (
                      <ClientUser
                        key={user.username}
                        clientId={clientId}
                        user={user}
                        setError={setError}
                      />
                    ))}
                  </List>
                </>
              )}
            </>
          ) : (
            <Box mt={2}>
              <Typography color="textSecondary">
                There are no users yet. Enter the email address (case-sensitive)
                of the user you wish to add and click the{' '}
                <Add fontSize="small" /> button (or simply press <i>Enter</i>).
              </Typography>
            </Box>
          )}
        </DialogContent>
      </Dialog>

      <ErrorSnackbar open={!!error} onClose={() => setError(null)}>
        <span>{error && error.message}</span>
      </ErrorSnackbar>
    </>
  );
}

export default React.memo<Props>(ClientUsersButton);
