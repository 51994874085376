// @flow

import * as React from 'react'
import classNames from 'classnames'
import { withStyles, IconButton, Snackbar, SnackbarContent } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'

const withStylesHOC = withStyles(theme => ({
  snackbarContent: {
    backgroundColor: theme.palette.error.dark
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    marginRight: theme.spacing(2)
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.body1.fontSize
  }
}))

type Props = {
  open: boolean,
  children: React.Node,
  onClose: () => void
}

class ErrorSnackbar extends React.Component<Props & WithStylesProps> {
  handleClose = (e, reason) => {
    if (reason !== 'clickaway') {
      this.props.onClose()
    }
  }

  render() {
    const { classes, open, children } = this.props
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={this.handleClose}
      >
        <SnackbarContent
          className={classes.snackbarContent}
          message={
            <span className={classes.message}>
              <ErrorIcon
                className={classNames(classes.icon, classes.iconVariant)}
              />
              {children}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
              data-cypress="error-snackbar-close"
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          ]}
        />
      </Snackbar>
    )
  }
}

export default (withStylesHOC(ErrorSnackbar): React.ComponentType<Props>)
