// @flow

export const getNotifications = async (limit: number = 6): Promise<Object> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        notifications: [],
        notificationsCount: 0
      });
    }, 700);
  });
};
