// @flow

import * as React from 'react';
// Externals
import classNames from 'classnames';

import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';

// Material components
import { makeStyles, Button, Popover } from '@material-ui/core';

// Shared components
import { SearchInput } from 'components';
import { ErrorSnackbar } from 'components/Snackbars';
import LoadingButton from 'components/LoadingButton';

// Component styles
const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center'
  },
  spacer: {
    flexGrow: 1
  },
  advancedFilter: {
    padding: theme.spacing(3)
  },
  advancedFilterButton: {
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(0.25)
  },
  refreshButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

type Props = {
  className?: string,
  filter: string,
  onChangeFilter: (SyntheticInputEvent<HTMLInputElement>) => void,
  createFormUrl?: () => Promise<string>,
  addLabelText?: string,
  isLoading: boolean,
  onRefresh: () => void,
  advancedFilterComponent?: React.Node
};

function Toolbar({
  className,
  filter,
  onChangeFilter,
  createFormUrl,
  addLabelText,
  isLoading,
  onRefresh,
  advancedFilterComponent
}: Props) {
  const [isCreatingCreateUrl, setIsCreatingCreateUrl] = React.useState(false);
  const [createUrlError, setCreateUrlError] = React.useState(null);
  const [advancedFilterElement, setAdvancedFilterElement] = React.useState(
    null
  );
  const classes = useStyles();

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Filter"
          value={filter}
          onChange={onChangeFilter}
        />
        <span className={classes.spacer} />
        {advancedFilterComponent ? (
          <React.Fragment>
            <Button
              className={classes.advancedFilterButton}
              size="small"
              variant="outlined"
              disabled={isLoading}
              onClick={event => setAdvancedFilterElement(event.currentTarget)}>
              Advanced Filter <ArrowDropDownIcon />
            </Button>
            <Popover
              id="advanced-filter"
              open={!!advancedFilterElement}
              anchorEl={advancedFilterElement}
              onClose={() => setAdvancedFilterElement(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}>
              <div className={classes.advancedFilter}>
                {advancedFilterComponent}
              </div>
            </Popover>
          </React.Fragment>
        ) : null}
        <Button
          className={classNames({
            [classes.refreshButton]: !!createFormUrl
          })}
          size="small"
          variant="outlined"
          disabled={isLoading}
          onClick={onRefresh}>
          Refresh
        </Button>
        {createFormUrl ? (
          <LoadingButton
            color="primary"
            size="small"
            variant="outlined"
            loading={isCreatingCreateUrl}
            onClick={async () => {
              try {
                setCreateUrlError(null);
                setIsCreatingCreateUrl(true);
                const formUrl = await createFormUrl();
                window.location.href = formUrl;
              } catch (error) {
                setCreateUrlError(error);
                setIsCreatingCreateUrl(false);
              }
            }}>
            Add New {addLabelText}
          </LoadingButton>
        ) : null}
      </div>

      <ErrorSnackbar
        open={!!createUrlError}
        onClose={() => setCreateUrlError(null)}>
        <span>{createUrlError && createUrlError.message}</span>
      </ErrorSnackbar>
    </div>
  );
}

export default Toolbar;
