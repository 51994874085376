// @flow

import * as React from 'react';

// Externals
import moment from 'moment';

// Material components
import { TableRow, TableCell, TableSortLabel, Grid } from '@material-ui/core';

// Shared services
import {
  getAcicBatches,
  createCreateAcicBatchUrl
} from 'services/acic-batches';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared components
import { TableData } from 'components';

import DownloadXmlButton from './DownloadXmlButton';
import ExtractRecordStatusChip from 'components/ExtractRecordStatusChip';
import useTableStyles from 'components/TableData/styles';

const defaultSort = {
  orderingByProperty: 'createdAt',
  isOrderingAscending: false
};

function AcicBatchesList() {
  const tableClasses = useTableStyles();
  return (
    <DashboardLayout title="ACIC Batches">
      <TableData
        defaultSort={defaultSort}
        addLabelText="ACIC Batch"
        createFormUrl={createCreateAcicBatchUrl}
        getData={getAcicBatches}
        filterFn={(batchRecord, filter) =>
          batchRecord.status.toLowerCase().includes(filter.toLowerCase())
        }
        noDataMessage="There are no ACIC Batch Records"
        renderHeaderCells={(
          orderingByProperty,
          isOrderingAscending,
          onHeaderClick
        ) => (
          <TableRow>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'createdAt'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('createdAt')}>
                Date Created
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'updatedAt'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('updatedAt')}>
                Date Updated
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'numberOfApplications'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('numberOfApplications')}>
                No. of Applications
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'status'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('status')}>
                Status
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'tenant'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('tenant')}>
                Tenant
              </TableSortLabel>
            </TableCell>
            <TableCell align="left" />
          </TableRow>
        )}
        renderDataCells={batchRecord => (
          <TableRow key={batchRecord.id}>
            <TableCell>{moment(batchRecord.createdAt).format('LLL')}</TableCell>
            <TableCell>{moment(batchRecord.updatedAt).format('LLL')}</TableCell>
            <TableCell>{batchRecord.numberOfApplications}</TableCell>
            <TableCell>
              <ExtractRecordStatusChip status={batchRecord.status} />
            </TableCell>
            <TableCell>
              {batchRecord.tenant === 'C' ? 'Cogent' : 'VerifyNow'}
            </TableCell>
            <TableCell className={tableClasses.alignCellRight}>
              {batchRecord.status === 'COMPLETE' && (
                <Grid container spacing={1} wrap="nowrap">
                  <Grid item>
                    <DownloadXmlButton acicBatchId={batchRecord.id} />
                  </Grid>
                </Grid>
              )}
            </TableCell>
          </TableRow>
        )}
      />
    </DashboardLayout>
  );
}

export default AcicBatchesList;
