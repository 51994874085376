import AES256 from 'aes-everywhere';

import {
  apiUrl,
  getRequestAPI,
  postRequestAPI
} from 'services/api-crud-factory';

const ClientId = process.env.REACT_APP_COGNITO_CLIENT_ID || '';
const ID_TOKEN = `COGNITO_${ClientId}_ID_TOKEN`;

export const ENVIRONMENT_SUPPORTS_API_APPLICATIONS = !!apiUrl;

export async function getAPIApplications(): Promise {
  return await Promise.all([getRequestAPI('/internal/requests/getAll')]).then(
    ([requests]) => {
      return requests;
    }
  );
}
export async function getKeys(): Promise {
  return await Promise.all([getRequestAPI('/internal/requests/getKeys')]).then(
    ([requests]) => {
      return requests;
    }
  );
}
export async function updateRequestURL(body): Promise {
  return await Promise.all([
    postRequestAPI('/internal/requests/updateRequestURL', body)
  ]).then(([requests]) => {
    return requests;
  });
}
export async function updateStatus(body): Promise {
  return await Promise.all([
    postRequestAPI('/internal/requests/updateRequestStatus', body)
  ]).then(([requests]) => {
    return requests;
  });
}
export async function finaliseRequest(body): Promise {
  return await Promise.all([
    postRequestAPI('/internal/requests/finalise', body)
  ]).then(([requests]) => {
    return requests;
  });
}
export async function finaliseRequestWithoutResults(body): Promise {
  return await Promise.all([
    postRequestAPI('/internal/requests/finaliseWithoutResults', body)
  ]).then(([requests]) => {
    return requests;
  });
}
export async function cancelRequest(body): Promise {
  return await Promise.all([
    postRequestAPI('/internal/requests/cancel', body)
  ]).then(([requests]) => {
    return requests;
  });
}

export async function getCreds(): Promise {
  return new Promise(async (resolve, reject) => {
    try {
      const idToken = localStorage.getItem(ID_TOKEN);
      const keys = await getKeys();
      const decryptedKeys = await AES256.decrypt(keys.body, idToken);
      resolve(decryptedKeys);
    } catch (e) {
      reject(e);
    }
  });
}

export async function getGreenDocumentResults(
  applicationIds: string[]
): Promise<GreenDocumentResult[]> {
  return await postRequestAPI('/internal/requests/getDocumentResults', {
    idArray: applicationIds
  });
}

export const applicationStatuses = [
  {
    id: 'CLARIFICATION_REQUIRED',
    name: 'Clarification Required',
    description:
      'Applicant is required to provided more information before screening process can be completed.'
  },
  {
    id: 'APPLICANT_SUBMITTED',
    name: 'Submitted By Applicant',
    description:
      'Application has been submitted by the applicant and is ready for checking.'
  },
  {
    id: 'VERIFICATION_IN_PROGRESS',
    name: 'Started Screening Process',
    description: 'The screening process has been started.'
  },
  {
    id: 'QA_PENDING',
    name: 'Completed Screening Process (QA Pending)',
    description:
      'The screening process has been completed and is ready for a quality assurance check.'
  },
  {
    id: 'VERIFICATION_COMPLETE',
    name: 'Completed Screening Process',
    description:
      'The screening process has been completed and the outcome is available to the client.'
  },
  {
    id: 'PENDING_COMPLETE_NO_RESULT',
    name: 'Pending Completion Without Results',
    description:
      'The screening process is ready for completion, however results are not available'
  },
  {
    id: 'VERIFICATION_COMPLETE_NO_RESULT',
    name: 'Completed Screening Without Results',
    description:
      'The screening process has been completed, however results are not available.'
  },
  {
    id: 'CANCELLED',
    name: 'Cancelled',
    description: 'The screening process has been cancelled.'
  }
];
