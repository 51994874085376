// @flow

import * as React from 'react'
import { withStyles, Snackbar, SnackbarContent } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'

const withStylesHOC = withStyles(theme => ({
  snackbarContent: {
    backgroundColor: theme.palette.success.main
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing(2)
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.body1.fontSize
  }
}))

type Props = {
  open: boolean,
  children: React.Node,
  onClose: () => void
}

function SuccessSnackbar({
  classes,
  open,
  onClose,
  children
}: Props & WithStylesProps) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      open={open}
      onClose={(event, reason) => {
        if (reason === 'clickaway') {
          return
        }
        onClose()
      }}
      autoHideDuration={3000}
    >
      <SnackbarContent
        className={classes.snackbarContent}
        message={
          <span className={classes.message}>
            <CheckIcon className={classes.icon} />
            {children}
          </span>
        }
      />
    </Snackbar>
  )
}

export default (withStylesHOC(SuccessSnackbar): React.ComponentType<Props>)
