// @flow

import * as React from 'react';

// Externals
import moment from 'moment';

// Material components
import {
  makeStyles,
  Box,
  TableRow,
  TableCell,
  Typography,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Chip,
  Tooltip
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Close as CloseIcon } from '@material-ui/icons';

// $FlowFixMe
import '@oneblink/apps-react/dist/styles.css';
import { OneBlinkForm } from '@oneblink/apps-react';

// Shared services
import {
  createCheckUrl,
  generateViewScreeningOutcomeForm
} from 'services/applications';

// Shared components
import {
  ApplicationStatusChip,
  VerificationTypeStatusChip,
  ApplicationGreenDocumentStatus
} from 'components';
import { ErrorSnackbar } from 'components/Snackbars';
import SendReminderEmailButton from './SendReminderEmailButton';
import ResendEmailButton from './ResendEmailButton';
import CancelApplicationButton from './CancelApplicationButton';
import EditApplicationStatusNoteButton from './EditApplicationStatusNoteButton';
import DeleteApplicationStatusNoteButton from './DeleteApplicationStatusNoteButton';
import LoadingButton from 'components/LoadingButton';

// Hooks
import useIsAdministrator from 'hooks/useIsAdministrator';
import { useSelectApplication } from 'hooks/useSelectApplications';
import useTableStyles from 'components/TableData/styles';

const useStyles = makeStyles(theme => ({
  lineBreaks: {
    whiteSpace: 'pre-line'
  },
  tableRow: {
    cursor: 'pointer'
  },
  dialogContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    '& .ob-buttons-submit': {
      display: 'none'
    },
    '& .ob-form': {
      paddingBottom: 0
    },
    '& .ob-information__content': {
      whiteSpace: 'pre-wrap'
    }
  },
  verificationTypesList: {
    border: `1px solid ${theme.palette.border}`,
    borderBottomWidth: '0'
  },
  dialogActions: {
    justifyContent: 'space-between'
  },
  selectCheckbox: {
    marginTop: -9,
    marginBottom: -9
  },
  successChip: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main
  },
  errorChip: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main
  }
}));

type Props = {
  application: Application,
  onChange: Application => void
};

function GroupFlexChildren({
  wrap,
  children
}: {
  wrap: boolean,
  children: React.Node
}) {
  if (wrap) {
    return <Box display="flex">{children}</Box>;
  }

  return children;
}

function ApplicationTableRow({ application, onChange }: Props) {
  const [isCreatingCreateUrl, setIsCreatingCreateUrl] = React.useState(false);
  const [createUrlError, setCreateUrlError] = React.useState(null);
  const [isApplicationSelected, setIsApplicationSelected] = React.useState(
    false
  );
  const [
    isCreatingViewScreeningOutcomeUrl,
    setIsCreatingViewScreeningOutcomeUrl
  ] = React.useState(false);
  const [
    viewScreeningOutcomeUrlError,
    setViewScreeningOutcomeUrlError
  ] = React.useState(null);
  const [
    viewScreeningOutcomeForm,
    setViewScreeningOutcomeForm
  ] = React.useState(null);

  const isAdministrator = useIsAdministrator();
  const {
    applicationIdIsSelected,
    selectApplicationId,
    deselectApplicationId
  } = useSelectApplication(application.id);

  const deselectApplication = React.useCallback(
    () => setIsApplicationSelected(false),
    []
  );
  const handleUpdated = React.useCallback(
    updatedApplication => {
      deselectApplication();
      onChange(updatedApplication);
    },
    [deselectApplication, onChange]
  );

  const classes = useStyles();
  const tableClasses = useTableStyles();
  const shouldShowStartCheckButton = React.useMemo(
    () =>
      (application.status === 'APPLICANT_SUBMITTED' ||
        application.status === 'VERIFICATION_IN_PROGRESS' ||
        application.status === 'QA_PENDING') &&
      isAdministrator,
    [application.status, isAdministrator]
  );

  const shouldShowSendReminderButton = React.useMemo(
    () =>
      (application.status === 'NEW' ||
        application.status === 'APPLICANT_IN_PROGRESS') &&
      isAdministrator,
    [application.status, isAdministrator]
  );

  const shouldShowCancelButton = React.useMemo(
    () => application.status !== 'CANCELLED',
    [application.status]
  );

  const shouldShowViewScreeningOutcomeButton = React.useMemo(
    () => application.status === 'VERIFICATION_COMPLETE',
    [application.status]
  );

  const shouldShowResendEmailButton = React.useMemo(
    () =>
      application.status === 'NEW' || application.status === 'CHALLENGE_FAILED',
    [application.status]
  );
  return (
    <>
      <TableRow
        key={application.id}
        hover
        onClick={() => setIsApplicationSelected(true)}
        className={classes.tableRow}>
        <TableCell>
          <Checkbox
            color="primary"
            className={classes.selectCheckbox}
            checked={applicationIdIsSelected}
            onChange={e =>
              e.target.checked ? selectApplicationId() : deselectApplicationId()
            }
            onClick={e => e.stopPropagation()}
          />
        </TableCell>
        <TableCell>{application.applicationNumber}</TableCell>
        <TableCell>{application.client.name}</TableCell>
        <TableCell>
          {application.applicantDetails.surname},{' '}
          {application.applicantDetails.firstName} (
          {application.applicantDetails.email})
        </TableCell>
        <TableCell>{application.referenceNumber}</TableCell>
        <TableCell>{moment(application.createdAt).format('LLL')}</TableCell>
        <TableCell>
          {application.applicantSubmissionTimestamp
            ? moment(application.applicantSubmissionTimestamp).format('LLL')
            : ''}
        </TableCell>
        {isAdministrator && (
          <TableCell className={tableClasses.alignCellCenter}>
            <ApplicationGreenDocumentStatus
              greenDocumentResult={application.greenDocumentResult}
            />
          </TableCell>
        )}
        <TableCell className={tableClasses.alignCellRight}>
          <ApplicationStatusChip status={application.status} />
        </TableCell>
      </TableRow>

      <Dialog
        maxWidth="md"
        fullWidth
        open={isApplicationSelected}
        onClose={deselectApplication}>
        <DialogTitle disableTypography>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={false}>
              <Typography variant="h4">
                Application Summary ({application.applicationNumber})
              </Typography>
            </Grid>
            <Grid item xs>
              <ApplicationStatusChip status={application.status} />
            </Grid>
            <Grid item xs={false}>
              <IconButton onClick={deselectApplication}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          {(application.statusNote || isAdministrator) && (
            <Box marginBottom={3}>
              <Alert
                severity="info"
                action={
                  isAdministrator ? (
                    <>
                      <EditApplicationStatusNoteButton
                        application={application}
                        onUpdated={handleUpdated}
                      />
                      {application.statusNote && (
                        <DeleteApplicationStatusNoteButton
                          application={application}
                          onUpdated={handleUpdated}
                        />
                      )}
                    </>
                  ) : (
                    undefined
                  )
                }>
                <AlertTitle>Status Notes</AlertTitle>
                {application.statusNote}
              </Alert>
            </Box>
          )}
          {!!application.emailReminderLastSentTimestamp && (
            <Box marginBottom={3}>
              <Alert severity="info">
                <AlertTitle>Applicant Reminder Email</AlertTitle>
                <div>
                  {application.applicantDetails.firstName} was sent a reminder
                  email to complete their application on{' '}
                  {moment(application.emailReminderLastSentTimestamp).format(
                    'LLL'
                  )}
                </div>
              </Alert>
            </Box>
          )}
          {!!application.refereeEmailReminderLastSentTimestamp && (
            <Box marginBottom={3}>
              <Alert severity="info">
                <AlertTitle>Reference Reminder Emails</AlertTitle>
                <div>
                  Referees were sent a reminder email to complete their
                  responses on{' '}
                  {moment(
                    application.refereeEmailReminderLastSentTimestamp
                  ).format('LLL')}
                </div>
              </Alert>
            </Box>
          )}
          {application.status === 'CANCELLED' && !!application.cancelledNote && (
            <Box marginBottom={3}>
              <Alert severity="warning">
                <AlertTitle>Cancelled Notes</AlertTitle>
                <div className={classes.lineBreaks}>
                  {application.cancelledNote}
                </div>
              </Alert>
            </Box>
          )}
          <Grid container spacing={1}>
            <Grid item xs>
              <Typography variant="h5" gutterBottom>
                Applicant Details
              </Typography>

              <Typography variant="caption" gutterBottom>
                Full Name
              </Typography>
              <Typography variant="body1" paragraph>
                {application.applicantDetails.firstName}{' '}
                {application.applicantDetails.surname}
              </Typography>

              <Typography variant="caption" gutterBottom>
                Email Address
              </Typography>
              <Typography variant="body1" paragraph>
                {application.applicantDetails.email}
              </Typography>

              <Typography variant="caption" gutterBottom>
                Phone Number
              </Typography>
              <Typography
                variant="body1"
                paragraph
                color={
                  application.applicantDetails.mobileNumber
                    ? 'initial'
                    : 'textSecondary'
                }>
                {application.applicantDetails.mobileNumber ||
                  'No phone number supplied'}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h5" gutterBottom>
                Application Details
              </Typography>
              <Typography variant="caption" gutterBottom>
                Client
              </Typography>
              <Typography variant="body1" paragraph>
                {application.client.name}
              </Typography>

              <Typography variant="caption" gutterBottom>
                Verification Package
              </Typography>
              <Typography variant="body1" paragraph>
                {application.verificationPackage.name}
              </Typography>

              <Typography variant="caption" gutterBottom>
                Reference Number
              </Typography>
              <Typography variant="body1" paragraph>
                {application.referenceNumber}
              </Typography>

              <Typography variant="caption" gutterBottom>
                Job Category
              </Typography>
              <Typography
                variant="body1"
                paragraph
                color={application.jobCategory ? 'initial' : 'textSecondary'}>
                {application.jobCategory || 'No job category supplied'}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h5" gutterBottom>
                Timeline
              </Typography>

              <Typography variant="caption" gutterBottom>
                Applicant Registration Date
              </Typography>
              <Typography variant="body1" paragraph>
                {moment(application.createdAt).format('LLL')}
              </Typography>

              <Typography variant="caption" gutterBottom>
                Applicant Submission Date
              </Typography>
              <Typography
                variant="body1"
                paragraph
                color={
                  application.applicantSubmissionTimestamp
                    ? 'inherit'
                    : 'textSecondary'
                }>
                {application.applicantSubmissionTimestamp
                  ? moment(application.applicantSubmissionTimestamp).format(
                      'LLL'
                    )
                  : 'TBD'}
              </Typography>

              <Typography variant="caption" gutterBottom>
                Screening Commencement Date
              </Typography>
              <Typography
                variant="body1"
                paragraph
                color={
                  application.screeningProcessStartedTimestamp
                    ? 'inherit'
                    : 'textSecondary'
                }>
                {application.screeningProcessStartedTimestamp
                  ? moment(application.screeningProcessStartedTimestamp).format(
                      'LLL'
                    )
                  : 'TBD'}
              </Typography>

              <Typography variant="caption" gutterBottom>
                Screening Completed Date
              </Typography>
              <Typography
                variant="body1"
                paragraph
                color={
                  shouldShowViewScreeningOutcomeButton
                    ? 'inherit'
                    : 'textSecondary'
                }>
                {shouldShowViewScreeningOutcomeButton
                  ? moment(application.updatedAt).format('LLL')
                  : 'TBD'}
              </Typography>
            </Grid>
          </Grid>

          {isAdministrator && (
            <Box marginBottom={3}>
              <Box marginBottom={2}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography variant="h5">Identity Verification</Typography>
                  </Grid>
                  {!!application.greenDocumentResult && (
                    <Grid item>
                      <ApplicationGreenDocumentStatus
                        greenDocumentResult={application.greenDocumentResult}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>

              {!!application.greenDocumentResult &&
              Array.isArray(application.greenDocumentResult.documents) &&
              !!application.greenDocumentResult.documents.length ? (
                <List disablePadding className={classes.verificationTypesList}>
                  {application.greenDocumentResult.documents.map(
                    (document, index) => {
                      let notesTooltip = '';
                      if (document.notes) {
                        if (typeof document.notes === 'string') {
                          notesTooltip = document.notes;
                        } else if (typeof document.notes.Error === 'string') {
                          notesTooltip = document.notes.Error;
                        }
                      }
                      const isVerified =
                        document.result.toUpperCase() === 'VERIFIED';
                      const isError = document.result.toUpperCase() === 'ERROR';
                      const isInProgress =
                        document.result.toUpperCase() === 'IN_PROGRESS';
                      return (
                        <React.Fragment key={index}>
                          <ListItem divider>
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography variant="body1">
                                  {document.documentCategory}
                                </Typography>
                              }
                              secondary={
                                <>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    gutterBottom>
                                    {document.documentType}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary">
                                    Verified: {document.verifiedOn}
                                  </Typography>
                                </>
                              }
                            />
                            <Tooltip title={notesTooltip}>
                              <Chip
                                label={document.result}
                                variant="outlined"
                                size="small"
                                color={isInProgress ? 'primary' : undefined}
                                className={
                                  isVerified
                                    ? classes.successChip
                                    : isError
                                    ? classes.errorChip
                                    : undefined
                                }
                              />
                            </Tooltip>
                          </ListItem>
                        </React.Fragment>
                      );
                    }
                  )}
                </List>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  There are no documents to display.
                </Typography>
              )}
            </Box>
          )}

          <Typography variant="h5" paragraph>
            Verifications
          </Typography>
          <List disablePadding className={classes.verificationTypesList}>
            {application.verificationTypeStatus.map(verificationTypeStatus => (
              <ListItem divider key={verificationTypeStatus.verificationTypeId}>
                <ListItemText
                  primary={verificationTypeStatus.verificationType.name}
                  secondary={
                    verificationTypeStatus.verificationType.description
                  }
                />
                <VerificationTypeStatusChip
                  status={verificationTypeStatus.status}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        {(shouldShowSendReminderButton ||
          shouldShowStartCheckButton ||
          shouldShowCancelButton ||
          shouldShowViewScreeningOutcomeButton ||
          shouldShowResendEmailButton) && (
          <DialogActions className={classes.dialogActions}>
            {shouldShowCancelButton ? (
              <CancelApplicationButton
                application={application}
                onCancelled={handleUpdated}
              />
            ) : null}

            {shouldShowViewScreeningOutcomeButton ? (
              <Box pl={1}>
                <LoadingButton
                  color="primary"
                  variant="outlined"
                  loading={isCreatingViewScreeningOutcomeUrl}
                  onClick={async () => {
                    try {
                      setViewScreeningOutcomeUrlError(null);
                      setIsCreatingViewScreeningOutcomeUrl(true);
                      const form = await generateViewScreeningOutcomeForm(
                        application.id
                      );
                      deselectApplication();
                      setViewScreeningOutcomeForm(form);
                    } catch (error) {
                      setViewScreeningOutcomeUrlError(error);
                    } finally {
                      setIsCreatingViewScreeningOutcomeUrl(false);
                    }
                  }}>
                  View Screening outcome
                </LoadingButton>
              </Box>
            ) : null}

            {shouldShowResendEmailButton || shouldShowSendReminderButton ? (
              <GroupFlexChildren
                wrap={
                  shouldShowResendEmailButton && shouldShowSendReminderButton
                }>
                {shouldShowResendEmailButton ? (
                  <Box pl={1}>
                    <ResendEmailButton
                      application={application}
                      onSent={onChange}
                    />
                  </Box>
                ) : null}
                {shouldShowSendReminderButton ? (
                  <Box pl={1}>
                    <SendReminderEmailButton
                      application={application}
                      onSent={onChange}
                    />
                  </Box>
                ) : null}
              </GroupFlexChildren>
            ) : null}
            {shouldShowStartCheckButton && (
              <Box pl={1}>
                <LoadingButton
                  color="primary"
                  variant="outlined"
                  loading={isCreatingCreateUrl}
                  onClick={async () => {
                    try {
                      setCreateUrlError(null);
                      setIsCreatingCreateUrl(true);
                      const formUrl = await createCheckUrl(application.id);
                      window.location.href = formUrl;
                    } catch (error) {
                      setCreateUrlError(error);
                      setIsCreatingCreateUrl(false);
                    }
                  }}>
                  {application.status === 'APPLICANT_SUBMITTED' &&
                    'Start Check'}
                  {application.status === 'VERIFICATION_IN_PROGRESS' &&
                    'Resume Check'}
                  {application.status === 'QA_PENDING' && 'Finalise Check'}
                </LoadingButton>
              </Box>
            )}
          </DialogActions>
        )}
      </Dialog>

      <Dialog
        maxWidth="md"
        fullWidth
        open={!!viewScreeningOutcomeForm}
        onClose={() => setViewScreeningOutcomeForm(null)}>
        <DialogTitle disableTypography>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography variant="h4">
                Application Outcome ({application.applicationNumber})
              </Typography>
            </Grid>
            <Grid item xs={false}>
              <IconButton onClick={() => setViewScreeningOutcomeForm(null)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <OneBlinkForm form={viewScreeningOutcomeForm} />
        </DialogContent>
      </Dialog>

      <ErrorSnackbar
        open={!!createUrlError}
        onClose={() => setCreateUrlError(null)}>
        <span>{createUrlError && createUrlError.message}</span>
      </ErrorSnackbar>
      <ErrorSnackbar
        open={!!viewScreeningOutcomeUrlError}
        onClose={() => setViewScreeningOutcomeUrlError(null)}>
        <span>
          {viewScreeningOutcomeUrlError && viewScreeningOutcomeUrlError.message}
        </span>
      </ErrorSnackbar>
    </>
  );
}

export default React.memo<Props>(ApplicationTableRow);
