// @flow

import * as React from 'react';
import useUserState from 'hooks/useUserState';

export default function useIsAdministrator() {
  const userState = useUserState();
  return React.useMemo(() => {
    return !!(
      userState.userDetails &&
      (userState.userDetails.isCogentAdministrator ||
        userState.userDetails.isVerifyNowAdministrator)
    );
  }, [userState.userDetails]);
}
