// @flow

import * as React from 'react';
import classNames from 'classnames';
import { withStyles, Typography } from '@material-ui/core';

const styles = theme => ({
  list: {
    margin: 0,
    'padding-top': theme.spacing(1),
    'padding-bottom': theme.spacing(1),
    'padding-right': theme.spacing(1),
    'padding-left': theme.spacing(4),
    '& > ul, & > ol': {
      'padding-top': 0,
      'padding-bottom': 0
    }
  },
  noPadding: {
    'padding-top': 0,
    'padding-bottom': 0
  },
  listItem: {
    display: 'list-item'
  },
  icon: {
    display: 'inline-block',
    width: theme.spacing(2),
    color: theme.palette.success.main
  },
  errorText: {
    color: theme.palette.error.main
  }
});

type Props = {
  children: React.Node,
  disablePadding?: boolean
};

function ol({ classes, disablePadding, children }: Props & WithStylesProps) {
  return (
    <ol
      className={classNames(classes.list, {
        [classes.noMargin]: disablePadding
      })}>
      {children}
    </ol>
  );
}

function ul({ classes, disablePadding, children }: Props & WithStylesProps) {
  return (
    <ul
      className={classNames(classes.list, {
        [classes.noMargin]: disablePadding
      })}>
      {children}
    </ul>
  );
}

function li({
  classes,
  children,
  ...props
}: Props & WithStylesProps & React$ElementProps<Typography>) {
  return (
    <Typography component="li" className={classes.listItem} {...props}>
      {children}
    </Typography>
  );
}

export const OrderedList = (withStyles(styles)(ol): React.ComponentType<Props>);
export const UnorderedList = (withStyles(styles)(
  ul
): React.ComponentType<Props>);
export const ListItem = (withStyles(styles)(li): React.ComponentType<Props>);

type ListItemWithIconProps = {
  error: boolean,
  children: React.Node
};

export const ListItemWithIcon = (withStyles(styles)(function({
  classes,
  error,
  children
}: ListItemWithIconProps & WithStylesProps) {
  return (
    <ListItem>
      <span
        className={classNames(classes.icon, {
          [classes.errorText]: error
        })}>
        {error ? <span>&#10005;</span> : <span>&#10003;</span>}
      </span>

      {children}
    </ListItem>
  );
}): React.ComponentType<ListItemWithIconProps>);
