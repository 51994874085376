import AWS from 'aws-sdk';

const DownloadDocuments = async (request) => {
  if(!request.imageoneURL && !request.pdfURL){
    alert("No document URL'S found");
    return;
  }
  let s3 = new AWS.S3();

  const documents = [{"document": "PDF", "key": "PDF-" + request.internalTrackingID + ".pdf", "extension": ".pdf"},{"document": "IMAGEONE", "key": "IMAGEONE-" + request.internalTrackingID + ".jpg", "extension": ".jpg"}];
  if(request.imagetwoURL){
    documents.push({"document": "IMAGETWO", "key": "IMAGETWO-" + request.internalTrackingID + ".jpg", "extension": ".jpg"})
  }
  if(request.pdftwoURL){
    documents.push({"document": "PDFTWO", "key": "PDFTWO-" + request.internalTrackingID + ".pdf", "extension": ".pdf"})
  }
  if(request.pdfthreeURL){
    documents.push({"document": "PDFTHREE", "key": "PDFTHREE-" + request.internalTrackingID + ".pdf", "extension": ".pdf"})
  }

  documents.forEach(async item => {
    const params = {
      Bucket: process.env.REACT_APP_VN_BUCKET,
      Key: item.key
    };

    return await s3.getObject(params, function(err, data) {
      if (err) {
        console.log(err, err.stack);
        return err;
      } else {
        let document = new Blob([data.Body]);
        var url = window.URL.createObjectURL(document);
        const fileName = request.internalTrackingID + "-" + request.applicantName + "-" + item.document + item.extension;
        downloadURL(url, fileName);
        return data;
      }
    })
  });
};

function downloadURL(url, name) {
  var link = document.createElement("a");
  link.download = name;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default DownloadDocuments;
