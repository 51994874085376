// @flow

import * as React from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button, TextField } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';

type Props = {
  profile: Profile,
  className: string
}

function Account({ classes, className, ...rest }: Props & WithStylesProps) {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const rootClassName = classNames(classes.root, className);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={e => {
        e.preventDefault();
      }}>
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader>
          <PortletLabel
            subtitle="The information can be edited"
            title="Profile"
          />
        </PortletHeader>
        <PortletContent>
            <TextField
              label="Email Address"
              margin="dense"
              required
              fullWidth
              value={email}
              variant="outlined"
              onChange={e => setEmail(e.target.value)}
            />
            <TextField
              label="First Name"
              margin="dense"
              fullWidth
              value={firstName}
              variant="outlined"
              onChange={e => setFirstName(e.target.value)}
            />
            <TextField
              label="Last Name"
              margin="dense"
              fullWidth
              value={lastName}
              variant="outlined"
              onChange={e => setLastName(e.target.value)}
            />
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button type="submit" color="primary" variant="outlined">
            Update
          </Button>
        </PortletFooter>
      </Portlet>
    </form>
  );
}

Account.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Account);
