// @flow

import * as React from 'react';
import { withRouter } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, withWidth } from '@material-ui/core';

// Material components
import { Drawer } from '@material-ui/core';

// Custom components
import { Sidebar, Topbar, Footer } from './components';

import { isSignedIn } from 'services/auth';

// Component styles
import styles from './styles';

type Props = {
  width: string,
  title: string,
  children: React.Node
};
type State = {
  isOpen: boolean,
  isSignedIn: boolean
};

class Dashboard extends React.Component<
  Props & WithStylesProps & WithHistoryProps,
  State
> {
  constructor(props) {
    super(props);

    const isMobile = ['xs', 'sm', 'md'].includes(props.width);

    this.state = {
      isOpen: !isMobile,
      isSignedIn: isSignedIn()
    };
  }

  componentDidMount() {
    if (!this.state.isSignedIn) {
      console.log(
        'Current user is not signed in, redirecting to sign in screen'
      );
      this.props.history.replace('/sign-in');
    }
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleToggleOpen = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  render() {
    const { classes, width, title, children } = this.props;
    const { isOpen, isSignedIn } = this.state;

    if (!isSignedIn) {
      return null;
    }

    const isMobile = ['xs', 'sm', 'md'].includes(width);
    const shiftTopbar = isOpen && !isMobile;
    const shiftContent = isOpen && !isMobile;

    return (
      <>
        <Topbar
          className={classNames(classes.topbar, {
            [classes.topbarShift]: shiftTopbar
          })}
          isSidebarOpen={isOpen}
          onToggleSidebar={this.handleToggleOpen}
          title={title}
        />
        <Drawer
          anchor="left"
          classes={{ paper: classes.drawerPaper }}
          onClose={this.handleClose}
          open={isOpen}
          variant={isMobile ? 'temporary' : 'persistent'}>
          <Sidebar className={classes.sidebar} />
        </Drawer>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: shiftContent
          })}>
          {children}
          <Footer />
        </main>
      </>
    );
  }
}

Dashboard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  width: PropTypes.string.isRequired
};

export default compose(
  withRouter,
  withStyles(styles),
  withWidth()
)(Dashboard);
