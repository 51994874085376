// @flow

import * as React from 'react';

// Externals
import moment from 'moment';

// Material components
import {
  makeStyles,
  Box,
  TableRow,
  TableCell,
  Typography,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  IconButton,
  Grid
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Close as CloseIcon } from '@material-ui/icons';

// $FlowFixMe
import '@oneblink/apps-react/dist/styles.css';

// Shared components
import { APIApplicationStatusChip } from 'components';
import { ErrorSnackbar } from 'components/Snackbars';
import CancelRequestButton from './CancelRequestButton';
import UploadResultButton from './UploadResultButton';
import CompleteButton from './CompleteButton';
import DownloadDocuments from './DownloadDocuments';
import DownloadResults from './DownloadResults';
import StatusChangeButton from './StatusChangeButton';
import LoadingButton from 'components/LoadingButton';

// Hooks
import useIsAdministrator from 'hooks/useIsAdministrator';

import useTableStyles from 'components/TableData/styles';

const useStyles = makeStyles(theme => ({
  lineBreaks: {
    whiteSpace: 'pre-line'
  },
  tableRow: {
    cursor: 'pointer'
  },
  dialogContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    '& .ob-buttons-submit': {
      display: 'none'
    },
    '& .ob-form': {
      paddingBottom: 0
    },
    '& .ob-information__content': {
      whiteSpace: 'pre-wrap'
    }
  },
  verificationTypesList: {
    border: `1px solid ${theme.palette.border}`,
    borderBottomWidth: '0'
  },
  dialogActions: {
    justifyContent: 'space-between'
  },
  selectCustom: {
    display: 'grid'
  }
}));

type Props = {
  request: Request,
  onChange: Request => void
};

function RequestTableRow({ request, onChange }: Props) {
  const [isDownloadingDocs, setIsDownloadingDocs] = React.useState(false);
  const [isDownloadingResults, setIsDownloadingResults] = React.useState(false);
  const [downloadDocsError, setDownloadDocsError] = React.useState(null);
  const [downloadResultsError, setDownloadResultsError] = React.useState(null);
  const [isRequestSelected, setIsRequestSelected] = React.useState(false);

  const isAdministrator = useIsAdministrator();

  const deselectApplication = React.useCallback(
    () => setIsRequestSelected(false),
    []
  );
  const handleCancelled = React.useCallback(
    updatedRequest => {
      deselectApplication();
      onChange(updatedRequest);
    },
    [deselectApplication, onChange]
  );
  const handleUploaded = React.useCallback(
    updatedRequest => {
      onChange(updatedRequest);
    },
    [onChange]
  );
  const handleCompleted = React.useCallback(
    updatedRequest => {
      onChange(updatedRequest);
    },
    [onChange]
  );
  const handleStatusChange = React.useCallback(
    updatedRequest => {
      onChange(updatedRequest);
    },
    [onChange]
  );

  const classes = useStyles();
  const tableClasses = useTableStyles();

  const shouldShowDownloadButton = React.useMemo(
    () => (request.imageoneURL || request.pdfURL) && isAdministrator,
    [request, isAdministrator]
  );
  const shouldShowDownloadResultsButton = React.useMemo(
    () =>
      request.resultURL && request.status === 'QA_PENDING' && isAdministrator,
    [request, isAdministrator]
  );
  const shouldShowCompleteButton = React.useMemo(
    () =>
      (request.resultURL || request.status === 'PENDING_COMPLETE_NO_RESULT') &&
      (request.status !== 'CANCELLED' &&
        request.status !== 'VERIFICATION_COMPLETE') &&
      isAdministrator,
    [request, isAdministrator]
  );
  const shouldShowUploadButton = React.useMemo(
    () =>
      request.status !== 'VERIFICATION_COMPLETE' &&
      request.status !== 'CANCELLED' &&
      request.status !== 'VERIFICATION_COMPLETE_NO_RESULT' &&
      request.status !== 'PENDING_COMPLETE_NO_RESULT' &&
      request.status !== 'QA_PENDING' &&
      isAdministrator,
    [request.status, isAdministrator]
  );
  const shouldShowCancelButton = React.useMemo(
    () =>
      request.status !== 'CANCELLED' &&
      request.status !== 'VERIFICATION_COMPLETE' &&
      request.status !== 'VERIFICATION_COMPLETE_NO_RESULT' &&
      isAdministrator,
    [request.status, isAdministrator]
  );

  return (
    <>
      <TableRow
        key={request._id}
        hover
        onClick={() => setIsRequestSelected(true)}
        className={classes.tableRow}>
        <TableCell>{request.internalTrackingID}</TableCell>
        <TableCell>{request.clientTrackingID}</TableCell>
        <TableCell>{request.clientName}</TableCell>
        <TableCell>{request.applicantName}</TableCell>
        <TableCell>{request.checkType}</TableCell>
        <TableCell>{request.state}</TableCell>
        <TableCell>{moment(request.createdAt).format('LLL')}</TableCell>
        <TableCell className={tableClasses.alignCellRight}>
          <APIApplicationStatusChip status={request.status} />
        </TableCell>
      </TableRow>

      <Dialog
        maxWidth="md"
        fullWidth
        open={isRequestSelected}
        onClose={deselectApplication}>
        <DialogTitle disableTypography>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={false}>
              <Typography variant="h4">
                Request Summary ({request.internalTrackingID})
              </Typography>
            </Grid>
            <Grid item xs>
              <APIApplicationStatusChip status={request.status} />
            </Grid>
            <Grid item xs={false}>
              <IconButton onClick={deselectApplication}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          {request.status === 'CANCELLED' && !!request.cancelledNote && (
            <Box marginBottom={3}>
              <Alert severity="warning">
                <AlertTitle>Cancelled Note</AlertTitle>
                <div className={classes.lineBreaks}>
                  {request.cancelledNote}
                </div>
              </Alert>
            </Box>
          )}
          <Grid container>
            <Grid item xs>
              <Typography variant="h5" gutterBottom>
                Miscellaneous Details
              </Typography>
              <Typography variant="caption" gutterBottom>
                Full Name
              </Typography>
              <Typography variant="body1" paragraph>
                {request.applicantName}
              </Typography>
              <Typography variant="caption" gutterBottom>
                State
              </Typography>
              <Typography variant="body1" paragraph>
                {request.state}
              </Typography>
              <Typography variant="caption" gutterBottom>
                Result Upload Date
              </Typography>
              <Typography variant="body1" paragraph>
                {request.resultUploadedOn
                  ? moment(request.resultUploadedOn).format('LLL')
                  : 'N/A'}
              </Typography>
              <Typography variant="caption" gutterBottom>
                Completed Date
              </Typography>
              <Typography variant="body1" paragraph>
                {request.completedOn
                  ? moment(request.completedOn).format('LLL')
                  : 'N/A'}
              </Typography>
              <Typography variant="caption" gutterBottom>
                Cancelled Date
              </Typography>
              <Typography variant="body1" paragraph>
                {request.cancelledOn
                  ? moment(request.cancelledOn).format('LLL')
                  : 'N/A'}
              </Typography>
              <Typography variant="caption" gutterBottom>
                Status Last Changed
              </Typography>
              <Typography variant="body1" paragraph>
                {request.statusChanges && request.statusChanges.length > 0
                  ? moment(
                      request.statusChanges[request.statusChanges.length - 1]
                        .changedOn
                    ).format('LLL')
                  : 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h5" gutterBottom>
                Request Details
              </Typography>
              <Typography variant="caption" gutterBottom>
                Client
              </Typography>
              <Typography variant="body1" paragraph>
                {request.clientName}
              </Typography>

              <Typography variant="caption" gutterBottom>
                Client Tracking ID
              </Typography>
              <Typography variant="body1" paragraph>
                {request.clientTrackingID}
              </Typography>

              <Typography variant="caption" gutterBottom>
                Internal Tracking ID
              </Typography>
              <Typography variant="body1" paragraph>
                {request.internalTrackingID}
              </Typography>

              <Typography variant="caption" gutterBottom>
                Request Type
              </Typography>
              <Typography variant="body1" paragraph>
                {request.checkType}
              </Typography>

              <Typography variant="caption" gutterBottom>
                Select New Status
              </Typography>
              <div>
                <select
                  className="selectCustom"
                  defaultValue="default"
                  id={'status-' + request._id}>
                  <option value="default" disabled>
                    Select a new Status
                  </option>
                  <option value="APPLICANT_SUBMITTED">
                    Submitted By Applicant
                  </option>
                  <option value="CLARIFICATION_REQUIRED">
                    Clarification Required
                  </option>
                  <option value="VERIFICATION_IN_PROGRESS">
                    Verification In Progress
                  </option>
                  <option value="QA_PENDING">
                    Completed Screening Process (QA Pending)
                  </option>
                  <option value="PENDING_COMPLETE_NO_RESULT">
                    Pending Completion Without Results
                  </option>
                </select>
                <StatusChangeButton
                  request={request}
                  onCompleted={handleStatusChange}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {shouldShowCancelButton ? (
            <CancelRequestButton
              request={request}
              onCancelled={handleCancelled}
            />
          ) : null}
          {shouldShowDownloadButton && (
            <LoadingButton
              color="primary"
              variant="outlined"
              loading={isDownloadingDocs}
              onClick={async () => {
                try {
                  setDownloadDocsError(null);
                  setIsDownloadingDocs(true);
                  await DownloadDocuments(request);
                  setIsDownloadingDocs(false);
                } catch (error) {
                  setDownloadDocsError(error);
                  setIsDownloadingDocs(false);
                }
              }}>
              Download Documents
            </LoadingButton>
          )}
          {shouldShowDownloadResultsButton && (
            <LoadingButton
              color="primary"
              variant="outlined"
              loading={isDownloadingResults}
              onClick={async () => {
                try {
                  setDownloadResultsError(null);
                  setIsDownloadingResults(true);
                  await DownloadResults(request);
                  setIsDownloadingResults(false);
                } catch (error) {
                  setDownloadResultsError(error);
                  setIsDownloadingResults(false);
                }
              }}>
              Download Results
            </LoadingButton>
          )}
          {shouldShowUploadButton && (
            <UploadResultButton request={request} onUploaded={handleUploaded} />
          )}
          {shouldShowCompleteButton && (
            <CompleteButton request={request} onCompleted={handleCompleted} />
          )}
        </DialogActions>
      </Dialog>

      <ErrorSnackbar
        open={!!downloadDocsError}
        onClose={() => setDownloadDocsError(null)}>
        <span>{downloadDocsError}</span>
      </ErrorSnackbar>
      <ErrorSnackbar
        open={!!downloadResultsError}
        onClose={() => setDownloadResultsError(null)}>
        <span>{downloadResultsError}</span>
      </ErrorSnackbar>
    </>
  );
}

export default React.memo<Props>(RequestTableRow);
