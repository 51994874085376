// @flow

import * as React from 'react';

// Material components
import { Tooltip, makeStyles } from '@material-ui/core';
import { CheckCircle, Cancel, Help, Warning } from '@material-ui/icons';

const useStyles = makeStyles(theme => {
  const block = {
    display: 'block',
    margin: 'auto'
  };
  return {
    block,
    passedIcon: {
      ...block,
      color: theme.palette.success.main
    },
    failedIcon: {
      ...block,
      color: theme.palette.error.main
    },
    warningIcon: {
      ...block,
      color: theme.palette.warning.main
    }
  };
});
type Props = {
  greenDocumentResult?: GreenDocumentResult
};

function ApplicationGreenDocumentStatus({ greenDocumentResult }: Props) {
  const classes = useStyles();

  if (!greenDocumentResult) {
    return (
      <Tooltip title="No green document data for this application">
        <div className={classes.block}>-</div>
      </Tooltip>
    );
  }

  if (greenDocumentResult.submissionStatus === 'Passed') {
    return (
      <Tooltip title={greenDocumentResult.submissionStatus}>
        <CheckCircle className={classes.passedIcon} />
      </Tooltip>
    );
  }
  if (greenDocumentResult.submissionStatus === 'Failed') {
    return (
      <Tooltip title={greenDocumentResult.submissionStatus}>
        <Cancel className={classes.failedIcon} />
      </Tooltip>
    );
  }
  if (greenDocumentResult.submissionStatus === 'Missing Initial Data') {
    return (
      <Tooltip title={greenDocumentResult.submissionStatus}>
        <Help className={classes.block} color="action" />
      </Tooltip>
    );
  }
  return (
    <Tooltip title={`Unknown Status: ${greenDocumentResult.submissionStatus}`}>
      <Warning className={classes.block} />
    </Tooltip>
  );
}

export default React.memo<Props>(ApplicationGreenDocumentStatus);
