// @flow

import * as React from 'react';

import {
  Button
} from '@material-ui/core';
import ErrorSnackbar from 'components/Snackbars/ErrorSnackbar';
import SuccessSnackbar from 'components/Snackbars/SuccessSnackbar';
import { updateStatus } from 'services/api-applications';

type Props = {
  request: Request,
  onCompleted: Request => void
};

function StatusChangeButton({ request, onCompleted }: Props) {
  const [isSuccessOpen, setIsSuccessOpen] = React.useState(false);

  const [error, setError] = React.useState(null);

  const handleConfirm = React.useCallback(async () => {
    setError(null);

    try {
      if(request.status === 'VERIFICATION_COMPLETE' || request.status === 'VERIFICATION_COMPLETE_NO_RESULT'){
        setError({message: 'Application is already complete, cannot update status'});
        return
      }
      if(request.status === 'CANCELLED'){
        setError({message: 'Application is cancelled, cannot update status'});
        return
      }

      let selectedstatus = document.getElementById('status-' + request._id).value;
      if(selectedstatus === 'default'){
        setError({message:'No status selected'});
        return
      }

      request.status = selectedstatus;
      const updatedRequest = await updateStatus(request);
      onCompleted(updatedRequest);
      setIsSuccessOpen(true);
    } catch (err) {
      setError(err);
    }

  }, [request, onCompleted]);

  return (
    <>
      <Button variant="outlined" onClick={handleConfirm}>
        Update Status
      </Button>

      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={() => setIsSuccessOpen(false)}>
        Status Successfully Updated
      </SuccessSnackbar>

      <ErrorSnackbar open={!!error} onClose={() => setError(null)}>
        <span>{error && error.message}</span>
      </ErrorSnackbar>
    </>
  );
}

export default React.memo<Props>(StatusChangeButton);
