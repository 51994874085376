// @flow

import * as React from 'react';

// Externals
import moment from 'moment';

// Material components
import { TableRow, TableCell, TableSortLabel } from '@material-ui/core';

// Shared services
import {
  getVerificationTypes,
  createCreateVerificationTypesUrl
} from 'services/verification-types';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared components
import { TableData } from 'components';

function VerificationTypeList() {
  return (
    <DashboardLayout title="Verification Types">
      <TableData
        addLabelText="Verification Type"
        createFormUrl={createCreateVerificationTypesUrl}
        getData={getVerificationTypes}
        filterFn={(verificationType, filter) =>
          verificationType.name.toLowerCase().includes(filter.toLowerCase())
        }
        noDataMessage="There are no verification types"
        renderHeaderCells={(
          orderingByProperty,
          isOrderingAscending,
          onHeaderClick
        ) => (
          <TableRow>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'name'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('name')}>
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'description'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('description')}>
                Description
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'key'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('key')}>
                Key
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={orderingByProperty === 'createdAt'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('createdAt')}>
                Registered
              </TableSortLabel>
            </TableCell>
          </TableRow>
        )}
        renderDataCells={verificationType => (
          <TableRow key={verificationType.id}>
            <TableCell>{verificationType.name}</TableCell>
            <TableCell>{verificationType.description}</TableCell>
            <TableCell>{verificationType.key}</TableCell>
            <TableCell>
              {moment(verificationType.createdAt).format('LLL')}
            </TableCell>
          </TableRow>
        )}
      />
    </DashboardLayout>
  );
}

export default VerificationTypeList;
