import { makeStyles } from '@material-ui/core';
// These are being used to align cells because the MUI prop align is no longer working due to the css being overwritten by our bulma stuff which is included for a form somewhere.
export default makeStyles(theme => ({
  alignCellRight: {
    textAlign: 'right !important'
  },
  alignCellCenter: {
    textAlign: 'center !important'
  }
}));
