// @flow

import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Views
import AccountList from './views/AccountList';
import ClientList from './views/ClientList';
import SignIn from './views/SignIn';
import ResetPassword from './views/ResetPassword';
import Profile from './views/Profile';
import UnderDevelopment from './views/UnderDevelopment';
import NotFound from './views/NotFound';
import VerificationTypeList from './views/VerificationTypes';
import VerificationPackageList from './views/VerificationPackages';
import ApplicationsList from './views/Applications';
import APIRequestsList from './views/APIRequests';
import AcicBatchesList from './views/AcicBatches';
import NpcExtractRecords from './views/NpcExtractRecords';
import BlueCardExtractRecords from './views/BlueCardExtractRecords';
import NpcVeroExtractRecords from './views/NpcVeroExtractRecords';
export default function Routes() {
  return (
    <Switch>
      <Route component={ApplicationsList} exact path="/" />
      <Route component={APIRequestsList} exact path="/apirequests" />
      <Route component={AccountList} exact path="/accounts" />
      <Route component={ClientList} exact path="/clients" />
      <Route component={AcicBatchesList} exact path="/acic-batches" />
      <Route component={NpcExtractRecords} exact path="/npc-extract-records" />
      <Route
        component={NpcVeroExtractRecords}
        exact
        path="/npc-vero-extract-records"
      />
      <Route
        component={BlueCardExtractRecords}
        exact
        path="/blue-card-extract-records"
      />
      <Route
        component={VerificationTypeList}
        exact
        path="/verification-types"
      />
      <Route
        component={VerificationPackageList}
        exact
        path="/verification-packages"
      />
      <Route component={Profile} exact path="/profile" />
      <Route component={SignIn} exact path="/sign-in" />
      <Route component={ResetPassword} exact path="/reset-password" />
      <Route component={UnderDevelopment} exact path="/reset-password" />

      <Route component={NotFound} exact path="/not-found" />
      <Redirect to="/not-found" />
    </Switch>
  );
}
