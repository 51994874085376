// @flow

import { getRequest, postRequest } from 'services/crud-factory';

export async function getVerificationPackages(): Promise<
  VerificationPackage[]
> {
  const { verificationPackages } = await getRequest('/verification-packages');
  return verificationPackages;
}

export async function createCreateVerificationPackagesUrl(): Promise<string> {
  const { formUrl } = await postRequest('/verification-packages/create-url');
  return formUrl;
}
