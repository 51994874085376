// @flow

import chunk from 'lodash.chunk';

import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from 'services/crud-factory';
import { getClients } from 'services/clients';
import { getVerificationTypes } from 'services/verification-types';
import { getVerificationPackages } from 'services/verification-packages';
import {
  getGreenDocumentResults,
  ENVIRONMENT_SUPPORTS_API_APPLICATIONS
} from 'services/api-applications';
async function getApplicationsPages(offset: number): Promise<Application[]> {
  const { applications, meta } = await getRequest<{
    applications: Application[],
    meta: { nextOffset?: number }
  }>('/applications', {
    offset,
    limit: 500
  });
  if (meta && meta.nextOffset) {
    const nextApplications = await getApplicationsPages(meta.nextOffset);
    return [...applications, ...nextApplications];
  }

  return applications;
}

export async function getApplications(
  isAdministrator: boolean
): Promise<Application[]> {
  const [
    applications,
    clients,
    verificationTypes,
    verificationPackages
  ] = await Promise.all([
    getApplicationsPages(0),
    getClients(),
    getVerificationTypes(),
    getVerificationPackages()
  ]);
  const greenDocumentResults =
    isAdministrator && ENVIRONMENT_SUPPORTS_API_APPLICATIONS
      ? await getGreenDocumentResults(
          applications.map(({ applicationNumber }) => applicationNumber)
        )
      : [];

  return applications.map(application => {
    const verificationTypeStatuses: VerificationTypeStatus[] =
      application.verificationTypeStatus || [];
    const verificationTypeStatus = verificationTypeStatuses.map(
      verificationTypeStatus => ({
        ...verificationTypeStatus,
        verificationType: verificationTypes.find(
          verificationType =>
            verificationType.id === verificationTypeStatus.verificationTypeId
        ) || {
          name: 'Unknown Verification Type',
          description: null
        }
      })
    );
    const verificationPackage = verificationPackages.find(
      verificationPackage =>
        verificationPackage.id === application.verificationPackageId
    ) || {
      name: 'Unknown Verification package'
    };
    const client = clients.find(
      client => client.id === application.clientId
    ) || {
      name: 'Unknown Client'
    };
    return {
      ...application,
      client,
      verificationTypeStatus,
      verificationPackage,
      greenDocumentResult: greenDocumentResults.find(
        greenDoc => greenDoc.verificationId === application.applicationNumber
      )
    };
  });
}

export async function createCheckUrl(applicationId: number): Promise<string> {
  const { formUrl } = await postRequest(
    `/applications/${applicationId}/check-url`
  );
  return formUrl;
}

export async function sendReminderEmail(
  application: Application
): Promise<Application> {
  const updatedApplication = await postRequest(
    `/applications/${application.id}/send-reminder-email`
  );

  return {
    ...application,
    updatedAt: updatedApplication.updatedAt,
    emailReminderLastSentTimestamp:
      updatedApplication.emailReminderLastSentTimestamp
  };
}

export async function resendEmail(
  application: Application
): Promise<Application> {
  const newApplication = await postRequest(
    `/applications/${application.id}/resend-applicant-email`
  );
  return {
    ...newApplication,
    client: application.client,
    verificationPackage: application.verificationPackage,
    verificationTypeStatus: application.verificationTypeStatus
  };
}

export async function cancelApplication(
  application: Application,
  cancelledNote: string
): Promise<Application> {
  const updatedApplication = await putRequest(
    `/applications/${application.id}/cancel`,
    {
      cancelledNote
    }
  );

  return {
    ...application,
    status: updatedApplication.status,
    updatedAt: updatedApplication.updatedAt,
    cancelledNote: updatedApplication.cancelledNote
  };
}

export async function updateApplicationStatusNote(
  application: Application,
  statusNote: string
): Promise<Application> {
  const updatedApplication = await putRequest(
    `/applications/${application.id}/status-note`,
    {
      statusNote
    }
  );

  return {
    ...updatedApplication,
    client: application.client,
    verificationPackage: application.verificationPackage,
    verificationTypeStatus: application.verificationTypeStatus
  };
}

export async function deleteApplicationStatusNote(
  application: Application
): Promise<Application> {
  await deleteRequest(`/applications/${application.id}/status-note`);

  application.statusNote = undefined;
  return application;
}

export async function generateCreateApplicationUrl() {
  const { formUrl } = await getRequest('/applications/create-url');

  return formUrl;
}

export async function generateViewScreeningOutcomeForm(
  applicationId: number
): Promise<Form> {
  const formElements = await postRequest(
    `/applications/${applicationId}/screening-outcome-form-elements`
  );

  return {
    id: NaN,
    name: 'View Screening Outcome',
    description:
      'Allow a client to view the outcome of an application that has completed the screening process',
    organisationId: '5ce217399cc7461100000002',
    elements: formElements,
    isAuthenticated: true,
    submissionEvents: [],
    createdAt: '2019-12-18T02:32:54.000Z',
    updatedAt: '2020-11-24T06:47:48.000Z',
    isMultiPage: false,
    postSubmissionAction: 'CLOSE',
    redirectUrl: null,
    isInfoPage: false,
    formsAppEnvironmentId: NaN,
    tags: [],
    formsAppIds: [],
    publishEndDate: undefined,
    publishStartDate: undefined
  };
}

export async function sendApplicantReminderEmails(
  applicationIds: number[]
): Promise<{ total: number, sent: number }> {
  const batches = chunk(applicationIds, 10);
  let total = 0;
  let sent = 0;
  for (const batch of batches) {
    const response = await postRequest(`/applications/send-reminder-email`, {
      applicationIds: batch
    });
    total = total + response.total;
    sent = sent + response.sent;
  }
  return { total, sent };
}

export async function sendRefereeReminderEmails(
  applicationIds: number[]
): Promise<{ total: number, sent: number }> {
  const batches = chunk(applicationIds, 10);
  let total = 0;
  let sent = 0;
  for (const batch of batches) {
    const response = await postRequest(
      `/applications/send-reference-reminder-email`,
      {
        applicationIds: batch
      }
    );
    total = total + response.total;
    sent = sent + response.sent;
  }
  return { total, sent };
}

export const applicationStatuses = [
  {
    id: 'NEW',
    name: 'New',
    description: 'Application has been created by the client.'
  },
  {
    id: 'CLARIFICATION_REQUIRED',
    name: 'Clarification Required',
    description:
      'Applicant is required to provided more information before screening process can be completed.'
  },
  {
    id: 'CHALLENGE_FAILED',
    name: 'Applicant Challenge Failed',
    description: 'Applicant has failed the identity challenge.'
  },
  {
    id: 'APPLICANT_IN_PROGRESS',
    name: 'Started By Applicant',
    description: 'Applicant has begun completing the verification types.'
  },
  {
    id: 'APPLICANT_SUBMITTED',
    name: 'Submitted By Applicant',
    description:
      'Application has been submitted by the applicant and is ready for checking.'
  },
  {
    id: 'VERIFICATION_IN_PROGRESS',
    name: 'Started Screening Process',
    description:
      'The screening process has been started by a screening officer.'
  },
  {
    id: 'QA_PENDING',
    name: 'Completed Screening Process (QA Pending)',
    description:
      'The screening process has been completed by a screen officer and is ready for a quality assurance check.'
  },
  {
    id: 'VERIFICATION_COMPLETE',
    name: 'Completed Screening Process',
    description:
      'The screening process has been completed by a screen officer and the outcome is available to the client.'
  },
  {
    id: 'CANCELLED',
    name: 'Cancelled',
    description: 'The screening process has been cancelled.'
  }
];
