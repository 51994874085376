// @flow

import { getRequest, postRequest } from 'services/crud-factory';

export async function getBlueCardExtractRecords(): Promise<ExtractZipRecord[]> {
  const { blueCardExtractRecords } = await getRequest<{
    blueCardExtractRecords: ExtractZipRecord[]
  }>('/blue-card-extract-records');
  return blueCardExtractRecords.map(blueCardExtractRecord => ({
    ...blueCardExtractRecord,
    numberOfSubmissions: blueCardExtractRecord.submissionIds.length
  }));
}

export async function createBlueCardExtractRecordUrl(): Promise<string> {
  const { formUrl } = await postRequest(
    '/blue-card-extract-records/create-url'
  );
  return formUrl;
}

export async function getBlueCardExtractRecordFiles(
  id: number
): Promise<
  Array<{
    fileName: string,
    url: string
  }>
> {
  const { files } = await postRequest(
    `/blue-card-extract-records/${id}/zip-file`
  );
  return files;
}
