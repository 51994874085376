// @flow

import { getRequest, postRequest } from 'services/crud-factory';
import { fileDownloadGetRequest } from 'services/file-download-service';
import { saveAs } from 'file-saver';

export async function getAcicBatches(): Promise<AcicXmlRecord[]> {
  const { acicExtracts } = await getRequest('/criminal-history-extract');

  return acicExtracts.map(record => {
    return {
      ...record,
      numberOfApplications: record.applicationIds.length
    };
  });
}

export async function createCreateAcicBatchUrl(): Promise<string> {
  const { formUrl } = await postRequest('/criminal-history-extract/create-url');
  return formUrl;
}

export async function downloadAndSaveXml(id: number): Promise<void> {
  const { blob, filename } = await fileDownloadGetRequest(
    `/criminal-history-extract/${id}/xml`,
    'application/xml'
  );
  saveAs(blob, filename || 'acic_batch.xml');
}
