// @flow

import * as React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  Typography
} from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';

import { addUserToAccount, getAccountUsers } from 'services/accounts';
import { ErrorSnackbar } from 'components/Snackbars';
import InputFieldRecordEntry from '../ClientUsersButton/InputFieldRecordEntry';
import AccountUser from './AccountUser';

interface Props {
  accountId: number;
}

function ClientUsersButton({ accountId }: Props) {
  const [isViewingUsers, setIsViewingUsers] = React.useState(false);

  const [isAddingUser, setIsAddingUser] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [newUser, setNewUser] = React.useState('');
  const [{ accountUsers, isLoading, loadError }, setState] = React.useState<{
    accountUsers: Array<VerifyNowAccountUser>,
    isLoading: boolean,
    loadError: Error | null
  }>({
    accountUsers: [],
    isLoading: false,
    loadError: null
  });

  const alreadyExists = React.useMemo(() => {
    return accountUsers.some(user => user.username === newUser);
  }, [accountUsers, newUser]);

  const handleAddUser = React.useCallback(async () => {
    setIsAddingUser(true);
    setError(null);
    try {
      const { username } = await addUserToAccount(accountId, newUser);
      setState(currentState => ({
        ...currentState,
        accountUsers: [
          {
            username,
            role: 'MANAGER'
          },
          ...currentState.accountUsers
        ]
      }));
      setNewUser('');
    } catch (err) {
      console.warn('An error occurred while adding a user', err);
      setError(err);
    }
    setIsAddingUser(false);
  }, [accountId, newUser, setError]);

  const handleNewUserChange = React.useCallback(e => {
    setNewUser(e.target.value);
  }, []);

  const handleDelete = React.useCallback(accountUser => {
    setState(currentState => ({
      ...currentState,
      accountUsers: currentState.accountUsers.filter(
        existingAccountUser =>
          existingAccountUser.username !== accountUser.username
      )
    }));
  }, []);

  React.useEffect(() => {
    const getUserData = async () => {
      if (!isViewingUsers) {
        setState({
          loadError: null,
          accountUsers: [],
          isLoading: false
        });
        return;
      }

      setState({
        loadError: null,
        accountUsers: [],
        isLoading: true
      });

      try {
        const newAccountUsers = await getAccountUsers(accountId);
        setState({
          loadError: null,
          accountUsers: newAccountUsers,
          isLoading: false
        });
      } catch (err) {
        console.warn('An error occurred while fetching users', err);
        setState({
          loadError: err,
          accountUsers: [],
          isLoading: false
        });
      }
    };
    getUserData();
  }, [isViewingUsers, accountId]);

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        onClick={() => setIsViewingUsers(true)}>
        Users
      </Button>

      <Dialog
        open={isViewingUsers}
        maxWidth="sm"
        fullWidth
        onClose={() => setIsViewingUsers(false)}>
        <DialogTitle disableTypography>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography variant="h4">Account Users</Typography>
            </Grid>
            <Grid item xs={false}>
              <IconButton onClick={() => setIsViewingUsers(false)}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers>
          <InputFieldRecordEntry
            autoFocus
            fullWidth
            label="Add User"
            placeholder="Enter the new user's email address"
            disabled={isAddingUser}
            value={newUser}
            onChange={handleNewUserChange}
            validationError={alreadyExists ? 'User already exists' : undefined}
            loading={isAddingUser}
            onSubmit={handleAddUser}
          />

          {isLoading ? (
            <CircularProgress />
          ) : loadError ? (
            <Box mt={2}>
              <Typography color="error">{loadError.message}</Typography>
            </Box>
          ) : accountUsers.length > 0 ? (
            <List>
              {accountUsers.map(user => (
                <AccountUser
                  key={user.username}
                  user={user}
                  setError={setError}
                  onDelete={handleDelete}
                  accountId={accountId}
                />
              ))}
            </List>
          ) : (
            <Box mt={2}>
              <Typography color="textSecondary">
                There are no users yet. Enter the email address (case-sensitive)
                of the user you wish to add and click the{' '}
                <Add fontSize="small" /> button (or simply press <i>Enter</i>).
              </Typography>
            </Box>
          )}
        </DialogContent>
      </Dialog>

      <ErrorSnackbar open={!!error} onClose={() => setError(null)}>
        <span>{error && error.message}</span>
      </ErrorSnackbar>
    </>
  );
}

export default React.memo<Props>(ClientUsersButton);
