// @flow

import * as React from 'react';

import {
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Box
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Edit as EditIcon } from '@material-ui/icons';
import ErrorSnackbar from 'components/Snackbars/ErrorSnackbar';
import SuccessSnackbar from 'components/Snackbars/SuccessSnackbar';
import LoadingButton from 'components/LoadingButton';
import { updateApplicationStatusNote } from 'services/applications';

type Props = {
  application: Application,
  onUpdated: Application => void
};

function EditApplicationStatusNoteButton({ application, onUpdated }: Props) {
  const [isShowingConfirmation, setIsShowingConfirmation] = React.useState(
    false
  );
  const [isSuccessOpen, setIsSuccessOpen] = React.useState(false);
  const [statusNote, setStatusNote] = React.useState('');
  const [isDirty, setIsDirty] = React.useState(false);

  const [isConfirming, setIsConfirming] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleConfirm = React.useCallback(async () => {
    setIsConfirming(true);
    setError(null);

    try {
      const updatedApplication = await updateApplicationStatusNote(
        application,
        statusNote
      );
      setIsShowingConfirmation(false);
      onUpdated(updatedApplication);
      setIsSuccessOpen(true);
    } catch (err) {
      setError(err);
    }

    setIsConfirming(false);
  }, [application, onUpdated, statusNote]);

  return (
    <>
      <IconButton
        color="inherit"
        onClick={() => setIsShowingConfirmation(true)}>
        <EditIcon />
      </IconButton>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={isShowingConfirmation}
        onClose={() => setIsShowingConfirmation(false)}
        onEntering={() => {
          setStatusNote(application.statusNote || '');
          setIsDirty(false);
        }}
        onExiting={() => {
          setError(null);
        }}>
        <DialogTitle disableTypography>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography variant="h4">Edit Application Status Note</Typography>
            </Grid>
            <Grid item xs={false}>
              <IconButton onClick={() => setIsShowingConfirmation(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Box paddingTop={1} paddingBottom={2}>
            <TextField
              InputLabelProps={{
                shrink: true
              }}
              multiline
              rowsMax={4}
              required
              label="Notes"
              value={statusNote}
              variant="outlined"
              fullWidth
              onChange={e => {
                setIsDirty(true);
                setStatusNote(e.target.value);
              }}
              placeholder="Please provide more information regarding the status of the application"
              error={isDirty && !statusNote}
              helperText={isDirty && !statusNote && 'Notes are required'}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="outlined"
            color="primary"
            loading={isConfirming}
            disabled={!statusNote}
            onClick={handleConfirm}>
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={() => setIsSuccessOpen(false)}>
        Application successfully updated
      </SuccessSnackbar>

      <ErrorSnackbar open={!!error} onClose={() => setError(null)}>
        <span>{error && error.message}</span>
      </ErrorSnackbar>
    </>
  );
}

export default React.memo<Props>(EditApplicationStatusNoteButton);
