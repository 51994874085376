// @flow
import * as React from 'react';
import { Checkbox } from '@material-ui/core';
import { useSelectApplications } from 'hooks/useSelectApplications';

type Props<T> = {
  filteredData: T[]
};
function SelectAllCheckbox<T: { id: number }>({ filteredData }: Props<T>) {
  const {
    allSelected,
    partlySelected,
    selectAllApplicationIds,
    deselectAllApplicationIds
  } = useSelectApplications(filteredData);
  return (
    <Checkbox
      color="primary"
      checked={allSelected}
      indeterminate={partlySelected}
      onChange={e =>
        e.target.checked
          ? selectAllApplicationIds()
          : deselectAllApplicationIds()
      }
    />
  );
}

export default SelectAllCheckbox;
