// @flow

import * as React from 'react';
import moment from 'moment';

import SuccessSnackbar from 'components/Snackbars/SuccessSnackbar';
import ErrorSnackbar from 'components/Snackbars/ErrorSnackbar';
import LoadingButton from 'components/LoadingButton';

import { sendReminderEmail } from 'services/applications';
import { Tooltip } from '@material-ui/core';
type Props = {
  application: Application,
  onSent: Application => void
};

const SendReminderEmailButton = ({ application, onSent }: Props) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);

  const handleSend = React.useCallback(async () => {
    setLoading(true);
    try {
      const newApplication = await sendReminderEmail(application);
      onSent(newApplication);
      setSuccessMessage('Reminder sent.');
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  }, [application, onSent]);

  return (
    <>
      <Tooltip
        placement="left"
        title={
          application.emailReminderLastSentTimestamp
            ? `Last sent on ${moment(
                application.emailReminderLastSentTimestamp
              ).format('LLL')}`
            : 'Reminder has never been sent'
        }>
        <span>
          <LoadingButton
            variant="outlined"
            color="primary"
            onClick={handleSend}
            loading={loading}>
            Send Reminder
          </LoadingButton>
        </span>
      </Tooltip>
      <SuccessSnackbar
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}>
        {successMessage}
      </SuccessSnackbar>
      <ErrorSnackbar open={!!error} onClose={() => setError(null)}>
        {error && error.message}
      </ErrorSnackbar>
    </>
  );
};

export default React.memo<Props>(SendReminderEmailButton);
