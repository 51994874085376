// @flow

import * as React from 'react';
import { useHistory, Link, NavLink } from 'react-router-dom';

// Externals
import classNames from 'classnames';

// Material components
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  CircularProgress
} from '@material-ui/core';

// Material icons
import {
  Store as StoreIcon,
  PeopleOutlined as PeopleIcon,
  EmailOutlined as EmailIcon,
  AccountBoxOutlined as AccountBoxIcon,
  InfoOutlined as InfoIcon,
  CheckCircleOutline as VerificationTypesIcon,
  AllInboxOutlined as VerificationPackagesIcon,
  Security as NPCExtractRecordsIcon,
  Security as NPCVeroExtractRecordsIcon,
  SupervisorAccount as BlueCardExtractRecordsIcon,
  Code as AcicBatchesIcon,
  Apps as AppsIcon
} from '@material-ui/icons';

// Component styles
import useStyles from './styles';

import { clientFaqUrl, supportEmail } from 'config';

// Hooks
import useUserState from 'hooks/useUserState';
import useCanManageClients from 'hooks/useCanManageClients';
import useIsAdministrator from 'hooks/useIsAdministrator';
type Props = {
  className: string
};

const UserBio = ({
  user,
  className
}: {
  user: VerifyNowUser | void,
  className: string
}) => {
  if (!user || (!user.isCogentAdministrator && !user.isVerifyNowAdministrator))
    return null;

  const groups = [];
  if (user.isCogentAdministrator) {
    groups.push('Cogent');
  }
  if (user.isVerifyNowAdministrator) {
    groups.push('VerifyNow');
  }
  return (
    <Typography className={className} variant="caption">
      {groups.join(' & ')} Administrator
    </Typography>
  );
};

const Sidebar = ({ className }: Props) => {
  const { profile, userDetails, isLoading } = useUserState();
  const canManageClients = useCanManageClients();
  const isAdministrator = useIsAdministrator();
  const history = useHistory();
  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);

  if (isLoading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <nav className={rootClassName}>
      <div className={classes.logoWrapper}>
        <Link className={classes.logoLink} to="/">
          <img
            alt="VerifyNow logo"
            className={classes.logoImage}
            src="/images/logos/verify-now-logo.webp"
          />
        </Link>
      </div>
      <Divider className={classes.logoDivider} />
      {profile && (
        <div className={classes.profile}>
          <Link to="/profile">
            <Avatar
              alt={profile.email}
              className={classes.avatar}
              src={profile.picture || '/images/avatars/profile.png'}
            />
          </Link>
          <Typography className={classes.nameText} variant="h6">
            {profile.email}
          </Typography>
          <UserBio user={userDetails} className={classes.bioText} />
        </div>
      )}
      <Divider className={classes.profileDivider} />
      <List component="div" disablePadding>
        <NavLink to="/">
          <ListItem
            className={classNames({
              [classes.listItem]: history.location.pathname !== '/',
              [classes.activeListItem]: history.location.pathname === '/'
            })}
            component="span">
            <ListItemIcon className={classes.listItemIcon}>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Applications"
            />
          </ListItem>
        </NavLink>
        {isAdministrator ? (
          <>
            <NavLink to="/apirequests">
              <ListItem
                className={classNames({
                  [classes.listItem]:
                    history.location.pathname !== '/apirequests',
                  [classes.activeListItem]:
                    history.location.pathname === '/apirequests'
                })}
                component="span">
                <ListItemIcon className={classes.listItemIcon}>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="API Applications"
                />
              </ListItem>
            </NavLink>
            <NavLink to="/accounts">
              <ListItem
                className={classNames({
                  [classes.listItem]: history.location.pathname !== '/accounts',
                  [classes.activeListItem]:
                    history.location.pathname === '/accounts'
                })}
                component="span">
                <ListItemIcon className={classes.listItemIcon}>
                  <AppsIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="Accounts"
                />
              </ListItem>
            </NavLink>
            <NavLink to="/clients">
              <ListItem
                className={classNames({
                  [classes.listItem]: history.location.pathname !== '/clients',
                  [classes.activeListItem]:
                    history.location.pathname === '/clients'
                })}
                component="span">
                <ListItemIcon className={classes.listItemIcon}>
                  <StoreIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="Clients"
                />
              </ListItem>
            </NavLink>
            <NavLink to="/verification-types">
              <ListItem
                className={classNames({
                  [classes.listItem]:
                    history.location.pathname !== '/verification-types',
                  [classes.activeListItem]:
                    history.location.pathname === '/verification-types'
                })}
                component="span">
                <ListItemIcon className={classes.listItemIcon}>
                  <VerificationTypesIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="Verification Types"
                />
              </ListItem>
            </NavLink>
            <NavLink to="/verification-packages">
              <ListItem
                className={classNames({
                  [classes.listItem]:
                    history.location.pathname !== '/verification-packages',
                  [classes.activeListItem]:
                    history.location.pathname === '/verification-packages'
                })}
                component="span">
                <ListItemIcon className={classes.listItemIcon}>
                  <VerificationPackagesIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="Verification Packages"
                />
              </ListItem>
            </NavLink>
            <NavLink to="/acic-batches">
              <ListItem
                className={classNames({
                  [classes.listItem]:
                    history.location.pathname !== '/acic-batches',
                  [classes.activeListItem]:
                    history.location.pathname === '/acic-batches'
                })}
                component="span">
                <ListItemIcon className={classes.listItemIcon}>
                  <AcicBatchesIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="ACIC Batches"
                />
              </ListItem>
            </NavLink>
            <NavLink to="/npc-extract-records">
              <ListItem
                className={classNames({
                  [classes.listItem]:
                    history.location.pathname !== '/npc-extract-records',
                  [classes.activeListItem]:
                    history.location.pathname === '/npc-extract-records'
                })}
                component="span">
                <ListItemIcon className={classes.listItemIcon}>
                  <NPCExtractRecordsIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="AFP Batches"
                />
              </ListItem>
            </NavLink>
            <NavLink to="/npc-vero-extract-records">
              <ListItem
                className={classNames({
                  [classes.listItem]:
                    history.location.pathname !== '/npc-vero-extract-records',
                  [classes.activeListItem]:
                    history.location.pathname === '/npc-vero-extract-records'
                })}
                component="span">
                <ListItemIcon className={classes.listItemIcon}>
                  <NPCVeroExtractRecordsIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="AFP Vero Batches"
                />
              </ListItem>
            </NavLink>
            <NavLink to="/blue-card-extract-records">
              <ListItem
                className={classNames({
                  [classes.listItem]:
                    history.location.pathname !== '/blue-card-extract-records',
                  [classes.activeListItem]:
                    history.location.pathname === '/blue-card-extract-records'
                })}
                component="span">
                <ListItemIcon className={classes.listItemIcon}>
                  <BlueCardExtractRecordsIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="Blue Card Batches"
                />
              </ListItem>
            </NavLink>
          </>
        ) : null}
        <NavLink to="/profile">
          <ListItem
            className={classNames({
              [classes.listItem]: history.location.pathname !== '/profile',
              [classes.activeListItem]: history.location.pathname === '/profile'
            })}
            component="span">
            <ListItemIcon className={classes.listItemIcon}>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Profile"
            />
          </ListItem>
        </NavLink>
      </List>
      <Divider className={classes.listDivider} />
      <List
        component="div"
        disablePadding
        subheader={
          <ListSubheader className={classes.listSubheader}>
            Support
          </ListSubheader>
        }>
        <ListItem
          className={classes.listItem}
          component="a"
          href={`mailto:${supportEmail}`}
          target="_blank">
          <ListItemIcon className={classes.listItemIcon}>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary="Email support"
          />
        </ListItem>
        {canManageClients && (
          <a href={clientFaqUrl} target="_blank" rel="noopener noreferrer">
            <ListItem className={classes.listItem} component="span">
              <ListItemIcon className={classes.listItemIcon}>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Knowledge Base"
              />
            </ListItem>
          </a>
        )}
      </List>
    </nav>
  );
};

export default React.memo<Props>(Sidebar);
