// @flow

import { getRequest, postRequest } from 'services/crud-factory';

export async function getVerificationTypes(): Promise<VerificationType[]> {
  const { verificationTypes } = await getRequest('/verification-types');
  return verificationTypes;
}

export async function createCreateVerificationTypesUrl(): Promise<string> {
  const { formUrl } = await postRequest('/verification-types/create-url');
  return formUrl;
}

export const verificationTypeStatuses = [
  {
    id: 'NOT_STARTED',
    name: 'Not Started'
  },
  {
    id: 'IN_PROGRESS',
    name: 'In Progress'
  },
  {
    id: 'CLARIFICATION_REQUIRED',
    name: 'Clarification Required'
  },
  {
    id: 'COMPLETE',
    name: 'Complete'
  }
];
