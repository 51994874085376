export default theme => ({
  root: {},
  form: {
    // margin: theme.spacing(3)
  },
  cancelButton: {
    marginLeft: theme.spacing(1)
  },
  portletFooter: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
});
