// @flow

import { getRequest, postRequest } from 'services/crud-factory';

export async function getNpcExtractRecords(): Promise<ExtractZipRecord[]> {
  const { npcExtractRecords } = await getRequest<{
    npcExtractRecords: ExtractZipRecord[]
  }>('/npc-extract-records');
  return npcExtractRecords.map(npcExtractRecord => ({
    ...npcExtractRecord,
    numberOfSubmissions: npcExtractRecord.submissionIds.length
  }));
}

export async function createNpcExtractRecordUrl(): Promise<string> {
  const { formUrl } = await postRequest('/npc-extract-records/create-url');
  return formUrl;
}

export async function getNpcExtractRecordFiles(
  id: number
): Promise<
  Array<{
    fileName: string,
    url: string
  }>
> {
  const { files } = await postRequest(`/npc-extract-records/${id}/zip-file`);
  return files;
}
