// @flow

import * as React from 'react';
import AWS from 'aws-sdk';

// Externals
import useLocalstorage from '@rooks/use-localstorage';

// Material components
import {
  makeStyles,
  TableRow,
  TableCell,
  TableSortLabel,
  Typography,
  Checkbox
} from '@material-ui/core';

// Shared services
import {
  getAPIApplications,
  applicationStatuses,
  getCreds
} from 'services/api-applications';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared components
import { TableData } from 'components';
import RequestTableRow from './RequestTableRow';

import useTableStyles from 'components/TableData/styles';

const defaultSort = {
  orderingByProperty: 'createdAt',
  isOrderingAscending: false
};

const useStyles = makeStyles(theme => ({
  field: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1.5),
    display: 'flex',
    alignItems: 'center'
  }
}));

async function updateCreds() {
  const creds = await getCreds();
  AWS.config.update(JSON.parse(creds));
}

function APIRequestsList() {
  updateCreds();
  const defaultStatusFilter = {
    CLARIFICATION_REQUIRED: true,
    APPLICANT_SUBMITTED: true,
    VERIFICATION_IN_PROGRESS: true,
    QA_PENDING: true,
    VERIFICATION_COMPLETE: true,
    VERIFICATION_COMPLETE_NO_RESULT: true,
    CANCELLED: true
  };
  let [statusFilter, setStatusFilter] = useLocalstorage(
    'API_APPLICATIONS_STATUS_FILTER',
    defaultStatusFilter
  );
  if (!statusFilter) {
    statusFilter = defaultStatusFilter;
  }

  const classes = useStyles();
  const tableClasses = useTableStyles();
  return (
    <DashboardLayout title="API Applications">
      <TableData
        defaultSort={defaultSort}
        advancedFilterComponent={
          <React.Fragment>
            <Typography variant="h5">Filter by Status</Typography>
            {applicationStatuses.map(status => (
              <div className={classes.field} key={status.id}>
                <Checkbox
                  color="primary"
                  checked={!!statusFilter[status.id]}
                  onChange={event =>
                    setStatusFilter({
                      ...statusFilter,
                      // $FlowFixMe
                      [status.id]: event.target.checked
                    })
                  }
                />
                <div>
                  <Typography variant="body1">{status.name}</Typography>
                  <Typography variant="caption">
                    {status.description}
                  </Typography>
                </div>
              </div>
            ))}
          </React.Fragment>
        }
        getData={getAPIApplications}
        filterFn={(request, filter) => {
          const lowerCaseFilter = filter.toLowerCase();
          return (
            statusFilter[request.status] &&
            (request.clientTrackingID.toLowerCase().includes(lowerCaseFilter) ||
              request.internalTrackingID
                .toLowerCase()
                .includes(lowerCaseFilter) ||
              request.clientName.toLowerCase().includes(lowerCaseFilter) ||
              request.applicantName.toLowerCase().includes(lowerCaseFilter) ||
              request.state.toLowerCase().includes(lowerCaseFilter))
          );
        }}
        noDataMessage="There are no applications that match your filters"
        getId={request => request._id}
        renderHeaderCells={(
          orderingByProperty,
          isOrderingAscending,
          onHeaderClick
        ) => (
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderingByProperty === 'internalTrackingID'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('internalTrackingID')}>
                Internal Tracking ID
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderingByProperty === 'clientTrackingID'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('clientTrackingID')}>
                Client Tracking ID
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderingByProperty === 'clientName'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('clientName')}>
                Client Name
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderingByProperty === 'applicantName'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('applicantName')}>
                Applicant Name
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderingByProperty === 'checkType'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('checkType')}>
                Check Type
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderingByProperty === 'state'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('state')}>
                State
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderingByProperty === 'createdAt'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('createdAt')}>
                Date Received
              </TableSortLabel>
            </TableCell>
            <TableCell className={tableClasses.alignCellRight}>
              <TableSortLabel
                active={orderingByProperty === 'status'}
                direction={isOrderingAscending ? 'asc' : 'desc'}
                onClick={() => onHeaderClick('status')}>
                Status
              </TableSortLabel>
            </TableCell>
          </TableRow>
        )}
        renderDataCells={(request, onChange) => (
          <RequestTableRow
            key={request._id}
            request={request}
            onChange={onChange}
          />
        )}
      />
    </DashboardLayout>
  );
}

export default APIRequestsList;
