import * as React from 'react';
import { Button, CircularProgress } from '@material-ui/core';

type Props = {
  loading: boolean
} & React.ComponentProps<typeof Button>;

const LoadingButton = ({ loading, disabled, ...props }: Props) => {
  return (
    <Button disabled={loading || disabled} {...props}>
      <>
        <span style={{ opacity: loading ? 0 : undefined }}>
          {props.children}
        </span>
        {loading && (
          <CircularProgress
            style={{
              position: 'absolute'
            }}
            size={24}
          />
        )}
      </>
    </Button>
  );
};

export default React.memo(LoadingButton);
