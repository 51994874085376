// @flow

import * as React from 'react';

type ContextType = {
  selectedApplicationIds: number[],
  setSelectedApplicationIds: (ids: number[]) => void
};

const emptyFn = () => {};
const SelectApplicationsContext = React.createContext<ContextType>({
  selectedApplicationIds: [],
  setSelectedApplicationIds: emptyFn
});

export const SelectApplicationsContextProvider = ({
  children
}: {
  children: React.Node
}) => {
  const [selectedApplicationIds, setSelectedApplicationIds] = React.useState(
    []
  );

  const value = React.useMemo(() => {
    return {
      selectedApplicationIds,
      setSelectedApplicationIds
    };
  }, [selectedApplicationIds, setSelectedApplicationIds]);

  return (
    <SelectApplicationsContext.Provider value={value}>
      {children}
    </SelectApplicationsContext.Provider>
  );
};

export const useSelectApplication = (applicationId: number) => {
  const {
    selectedApplicationIds,
    setSelectedApplicationIds
  } = React.useContext(SelectApplicationsContext);

  const applicationIdIsSelected = React.useMemo(() => {
    return selectedApplicationIds.includes(applicationId);
  }, [selectedApplicationIds, applicationId]);

  const selectApplicationId = React.useCallback(() => {
    if (!selectedApplicationIds.includes(applicationId)) {
      setSelectedApplicationIds([...selectedApplicationIds, applicationId]);
    }
  }, [selectedApplicationIds, setSelectedApplicationIds, applicationId]);

  const deselectApplicationId = React.useCallback(() => {
    setSelectedApplicationIds(
      selectedApplicationIds.filter(selectedId => selectedId !== applicationId)
    );
  }, [selectedApplicationIds, setSelectedApplicationIds, applicationId]);

  return React.useMemo(() => {
    return {
      applicationIdIsSelected,
      selectApplicationId,
      deselectApplicationId
    };
  }, [applicationIdIsSelected, selectApplicationId, deselectApplicationId]);
};

export function useSelectApplications<T: { id: number }>(filteredData: T[]) {
  const {
    setSelectedApplicationIds,
    selectedApplicationIds
  } = React.useContext(SelectApplicationsContext);

  const {
    allSelected,
    partlySelected,
    selectedOutsideFilteredCount
  } = React.useMemo(() => {
    const selectedFromFiltered = filteredData.filter(record => {
      return selectedApplicationIds.includes(record.id);
    });
    return {
      allSelected: selectedFromFiltered.length === filteredData.length,
      partlySelected:
        !!selectedFromFiltered.length &&
        selectedFromFiltered.length < filteredData.length,
      selectedOutsideFilteredCount:
        selectedApplicationIds.length - selectedFromFiltered.length
    };
  }, [selectedApplicationIds, filteredData]);

  const selectAllApplicationIds = React.useCallback(() => {
    setSelectedApplicationIds(filteredData.map(i => i.id));
  }, [setSelectedApplicationIds, filteredData]);

  const deselectAllApplicationIds = React.useCallback(() => {
    setSelectedApplicationIds([]);
  }, [setSelectedApplicationIds]);

  return {
    selectedApplicationIds,
    allSelected,
    partlySelected,
    selectedOutsideFilteredCount,
    selectAllApplicationIds,
    deselectAllApplicationIds
  };
}
