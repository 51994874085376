// @flow

import * as React from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Grid,
  Typography,
  IconButton,
  Box
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import ErrorSnackbar from 'components/Snackbars/ErrorSnackbar';
import SuccessSnackbar from 'components/Snackbars/SuccessSnackbar';
import LoadingButton from 'components/LoadingButton';
import { finaliseRequest, finaliseRequestWithoutResults } from 'services/api-applications';

type Props = {
  request: Request,
  onCompleted: Request => void
};

function CompleteButton({ request, onCompleted }: Props) {
  const [isShowingConfirmation, setIsShowingConfirmation] = React.useState(
    false
  );
  const [isSuccessOpen, setIsSuccessOpen] = React.useState(false);

  const [isConfirming, setIsConfirming] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleConfirm = React.useCallback(async () => {
    setIsConfirming(true);
    setError(null);

    try {
      if(request.status === 'PENDING_COMPLETE_NO_RESULT'){
        const updatedRequest = await finaliseRequestWithoutResults(request);
        onCompleted(updatedRequest);
        setIsShowingConfirmation(false);
        setIsSuccessOpen(true);
      } else {
        const updatedRequest = await finaliseRequest(request);
        onCompleted(updatedRequest);
        setIsShowingConfirmation(false);
        setIsSuccessOpen(true);
      }
    } catch (err) {
      setError(err);
    }

    setIsConfirming(false);
  }, [request, onCompleted]);

  return (
    <>
      <Button variant="outlined" onClick={() => setIsShowingConfirmation(true)}>
        Complete Application
      </Button>

      <Dialog
        open={isShowingConfirmation}
        onClose={() => setIsShowingConfirmation(false)}
      >
        <DialogTitle disableTypography>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography variant="h4">Complete Application</Typography>
            </Grid>
            <Grid item xs={false}>
              <IconButton onClick={() => setIsShowingConfirmation(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Box paddingTop={1} paddingBottom={2}>
            <DialogContentText>
              Are you sure you want to complete this application? This will send the uploaded results to the client and cannot be undone.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="outlined"
            color="primary"
            loading={isConfirming}
            onClick={handleConfirm}>
            Complete Application
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={() => setIsSuccessOpen(false)}>
        Application Successfully Completed
      </SuccessSnackbar>

      <ErrorSnackbar open={!!error} onClose={() => setError(null)}>
        <span>{error && error.message}</span>
      </ErrorSnackbar>
    </>
  );
}

export default React.memo<Props>(CompleteButton);
