// @flow

import { getIdToken } from './auth';

export type Fetch = (string, Object) => Promise<any>;

const checkHeaders = (headers?: Headers): Headers => headers || new Headers();

function addAuthHeader(headers: Headers, jwt: string): Headers {
  headers.set('Authorization', 'Bearer ' + jwt);

  return headers;
}

function withAuth(requestFn: Fetch): Fetch {
  return async (input: string, init: Object): Promise<any> => {
    const jwt = await getIdToken();
    const opts: Object = Object.assign({}, init, {
      headers: addAuthHeader(checkHeaders(init.headers), jwt)
    });
    try {
      const response = await requestFn(input, opts);

      if (init.method === 'DELETE') {
        if (!response.ok) {
          const body = await response.json();
          throw new Error(body.message);
        }
      } else {
        const body = await response.json();
        if (response.ok) {
          return body;
        }

        throw new Error(body.message);
      }
    } catch (e) {
      if (e.message.toLowerCase() === 'failed to fetch') {
        e.message =
          'There has been a network error, please check your network and try again.';
      }
      throw e;
    }
  };
}

function fileDownloadWithAuth(requestFn: Fetch): Fetch {
  return async (input: string, init: Object): Promise<any> => {
    const jwt = await getIdToken();
    const opts: Object = Object.assign({}, init, {
      headers: addAuthHeader(checkHeaders(init.headers), jwt)
    });

    try {
      const response /* : Response */ = await requestFn(input, opts);
      if (!response.ok) {
        const body = await response.json();
        throw new Error(body.message);
      }
      return response;
    } catch (error) {
      let message = error.message;
      if (message.toLowerCase() === 'failed to fetch') {
        message =
          'There has been a network error, please check your network and try again.';
      }
      throw new Error(message);
    }
  };
}

export default withAuth;
export { addAuthHeader, checkHeaders, fileDownloadWithAuth };
