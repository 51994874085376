// @flow

import * as React from 'react';

import { getProfile } from 'services/auth';
import { getMyDetails } from 'services/users';

type UserState = {
  refresh: () => Promise<void>,
  profile: Profile | void,
  userDetails: VerifyNowUser | void,
  isLoading: boolean
};
export type WithUserStateProps = {
  userState: UserState
};

const initialState: UserState = {
  refresh: async () => {},
  profile: undefined,
  userDetails: undefined,
  isLoading: true
};

export const UserStateContext: React.Context<UserState> = React.createContext(
  initialState
);

export const UserStateProvider = ({ children }: { children: React.Node }) => {
  const [userState, setUserState] = React.useState(initialState);

  const refresh = React.useCallback(async () => {
    try {
      const profile = await getProfile();
      if (profile) {
        const userDetails = await getMyDetails();
        setUserState({
          profile,
          userDetails,
          isLoading: false
        });
      }
    } catch (error) {
      setUserState({
        profile: undefined,
        userDetails: undefined,
        isLoading: false
      });
      console.error('Could not load profile', error);
    }
  }, []);

  const value = React.useMemo<UserState>(
    () => ({
      ...userState,
      refresh
    }),
    [refresh, userState]
  );

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <UserStateContext.Provider value={value}>
      {children}
    </UserStateContext.Provider>
  );
};

export function withUserState<P: {}>(
  WrappedComponent: React.AbstractComponent<P>
): React.AbstractComponent<P & WithUserStateProps> {
  return function withUserState(props: P) {
    return (
      <UserStateContext.Consumer>
        {userState => <WrappedComponent {...props} userState={userState} />}
      </UserStateContext.Consumer>
    );
  };
}

export default function useUserState() {
  const userState = React.useContext(UserStateContext);

  return userState;
}
