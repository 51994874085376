// @flow

import React from 'react';
import ReactDOM from 'react-dom';

// Service worker
import * as serviceWorker from './common/serviceWorker';

// App
import App from './App';

const root = document.getElementById('root')
if (root) {
  ReactDOM.render(<App />, root);
  serviceWorker.unregister();
}

