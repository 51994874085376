// @flow

import * as React from 'react';

// Material components
import { Chip } from '@material-ui/core';

import { applicationStatuses } from 'services/applications';

import useStyles from './styles';

type Props = {
  status: ApplicationStatus
};

export function getStatusDisplayName(status: ApplicationStatus) {
  const applicationStatus = applicationStatuses.find(
    applicationStatus => applicationStatus.id === status
  );
  if (applicationStatus) {
    return applicationStatus.name;
  } else {
    return 'Unknown';
  }
}

function ApplicationStatusChip({ status }: Props) {
  const classes = useStyles();
  const chipProps = {
    clickable: false,
    color: 'default',
    label: getStatusDisplayName(status),
    size: 'small',
    variant: 'outlined',
    classes: {}
  };

  switch (status) {
    case 'NEW': {
      break;
    }
    case 'CLARIFICATION_REQUIRED': {
      chipProps.classes.root = classes.errorOutlined;
      break;
    }
    case 'CHALLENGE_FAILED': {
      chipProps.variant = 'default';
      chipProps.classes.root = classes.error;
      break;
    }
    case 'APPLICANT_IN_PROGRESS': {
      chipProps.color = 'primary';
      break;
    }
    case 'APPLICANT_SUBMITTED': {
      chipProps.color = 'primary';
      chipProps.variant = 'default';
      break;
    }
    case 'VERIFICATION_IN_PROGRESS': {
      chipProps.color = 'primary';
      break;
    }
    case 'QA_PENDING': {
      chipProps.classes.root = classes.successOutlined;
      break;
    }
    case 'VERIFICATION_COMPLETE': {
      chipProps.variant = 'default';
      chipProps.classes.root = classes.success;
      break;
    }
    case 'CANCELLED': {
      chipProps.classes.root = classes.warning;
      break;
    }
    default: {
      chipProps.variant = 'default';
      chipProps.classes.root = classes.warning;
    }
  }

  return <Chip {...chipProps} />;
}

export default React.memo<Props>(ApplicationStatusChip);
