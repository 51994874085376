// @flow

import queryString from 'querystring';

import withAuth from './withAuth';

const apiUrl = process.env.REACT_APP_VERIFY_NOW_API_URL || '';

const headers: Headers = new Headers();
headers.append('Content-Type', 'application/json');
headers.append('Accept', 'application/json');

export async function getRequest<T>(
  path: string,
  search?: { [key: string]: mixed }
): Promise<T> {
  const opts = {
    headers,
    mode: 'cors',
    cache: 'default',
    method: 'GET'
  };

  let url = `${apiUrl}${path}`;

  if (search) {
    url += `?${queryString.stringify(search)}`;
  }

  const body = await withAuth(fetch)(url, opts);

  return body;
}

export async function postRequest<T, OutT>(
  path: string,
  resource: T
): Promise<OutT> {
  const opts = {
    headers,
    mode: 'cors',
    cache: 'default',
    method: 'POST',
    body: JSON.stringify(resource)
  };

  const url = `${apiUrl}${path}`;

  return withAuth(fetch)(url, opts);
}

export async function putRequest<T, OutT>(
  path: string,
  resource: T
): Promise<OutT> {
  const opts = {
    headers,
    mode: 'cors',
    cache: 'default',
    method: 'PUT',
    body: JSON.stringify(resource)
  };

  const url = `${apiUrl}${path}`;

  return withAuth(fetch)(url, opts);
}

export async function deleteRequest(path: string): Promise<void> {
  const opts = {
    headers,
    mode: 'cors',
    cache: 'default',
    method: 'DELETE'
  };

  const url = `${apiUrl}${path}`;

  await withAuth(fetch)(url, opts);
}
