// @flow

import * as React from 'react';

// Externals
import orderBy from 'lodash.orderby';

// Material components
import {
  makeStyles,
  Table,
  TableBody,
  TableHead,
  TablePagination
} from '@material-ui/core';

// Shared components
import { Portlet, PortletContent } from 'components';

// Component styles
const useStyles = makeStyles(theme => ({
  root: {},
  tableContainer: {
    overflowX: 'auto'
  },
  tableRow: {
    height: '64px'
  }
}));

export type DefaultSort = {
  orderingByProperty: string,
  isOrderingAscending: boolean
};
export type OrderByProperty<T> = string | (T => string);
type Props<T> = {
  data: T[],
  renderHeaderCells: (
    OrderByProperty<T> | null,
    boolean,
    (OrderByProperty<T>) => void,
    T[]
  ) => React.Node,
  renderDataCells: (T, (T) => void) => React.Node,
  onChange: T => void,
  defaultSort?: DefaultSort
};

function TableComponent<T>({
  data,
  renderHeaderCells,
  renderDataCells,
  onChange,
  defaultSort
}: Props<T>) {
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [
    orderingByProperty,
    setOrderByProperty
  ] = React.useState<OrderByProperty<T> | null>(
    defaultSort ? defaultSort.orderingByProperty : null
  );
  const [isOrderingAscending, setIsOrderingAscending] = React.useState(
    defaultSort ? defaultSort.isOrderingAscending : true
  );
  const classes = useStyles();

  const orderedData = orderingByProperty
    ? orderBy(data, orderingByProperty, isOrderingAscending ? 'asc' : 'desc')
    : data;

  return (
    <Portlet className={classes.root}>
      <PortletContent noPadding>
        <div className={classes.tableContainer}>
          <Table>
            <TableHead>
              {renderHeaderCells(
                orderingByProperty,
                isOrderingAscending,
                propertyName => {
                  if (orderingByProperty === propertyName) {
                    setIsOrderingAscending(!isOrderingAscending);
                  } else {
                    // $FlowFixMe shut up flow....
                    setOrderByProperty(() => propertyName);
                    if (!isOrderingAscending) {
                      setIsOrderingAscending(true);
                    }
                  }
                },
                data
              )}
            </TableHead>
            <TableBody>
              {orderedData
                .slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage)
                .map(row => renderDataCells(row, onChange))}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          component="div"
          count={data.length}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          onChangePage={(event, page) => setPage(page)}
          onChangeRowsPerPage={event => {
            setPage(0);
            setRowsPerPage(event.target.value);
          }}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </PortletContent>
    </Portlet>
  );
}

export default TableComponent;
