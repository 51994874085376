// @flow

import * as React from 'react';
import useUserState from 'hooks/useUserState';
export default function useCanManageClients() {
  const userState = useUserState();
  return React.useMemo(
    () =>
      !!userState.userDetails &&
      (!!userState.userDetails.clients.length ||
        !!userState.userDetails.accounts.length),
    [userState.userDetails]
  );
}
