// @flow

import * as React from 'react';
import LoadingButton from 'components/LoadingButton';
import ErrorSnackbar from 'components/Snackbars/ErrorSnackbar';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Link,
  Typography,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { Close, Attachment } from '@material-ui/icons';

type File = {
  url: string,
  fileName: string
};

type Props = {
  id: number,
  disabled: boolean,
  getFiles: (id: number) => Promise<Array<File>>
};

function DownloadExtractButton({ id, disabled, getFiles }: Props) {
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<null | Error>(null);
  const [files, setFiles] = React.useState<File[]>([]);
  const [dialogIsOpen, setDialogIsOpen] = React.useState<boolean>(false);
  const handleDownload = React.useCallback(async () => {
    if (files.length) {
      setDialogIsOpen(true);
      return;
    }
    try {
      setIsDownloading(true);
      const files = await getFiles(id);
      setFiles(files);
      setDialogIsOpen(true);
    } catch (err) {
      setError(err);
    }
    setIsDownloading(false);
  }, [files, id, getFiles]);
  return (
    <>
      <LoadingButton
        size="small"
        variant="outlined"
        disabled={disabled}
        loading={isDownloading}
        onClick={handleDownload}>
        Download Files
      </LoadingButton>
      <DownloadDialog
        files={files}
        open={dialogIsOpen}
        onClose={() => setDialogIsOpen(false)}
      />
      <ErrorSnackbar open={!!error} onClose={() => setError(null)}>
        <span>{error && error.message}</span>
      </ErrorSnackbar>
    </>
  );
}

export default React.memo<Props>(DownloadExtractButton);

type DownloadDialogProps = {
  open: boolean,
  files: File[],
  onClose: () => void
};

const DownloadDialog = ({ open, files, onClose }: DownloadDialogProps) => {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle disableTypography>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs>
            <Typography variant="h4">Download Files</Typography>
          </Grid>
          <Grid item xs={false}>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent dividers>
        <List>
          {files.map(({ url, fileName }, index) => {
            return (
              <ListItem disableGutters key={fileName}>
                <ListItemIcon>
                  <Attachment />
                </ListItemIcon>
                <ListItemText>
                  <Link href={url} target="_blank">
                    {fileName}
                  </Link>
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
};
