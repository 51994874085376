// @flow

import * as React from 'react';

// Material components
import { makeStyles, CircularProgress, Typography } from '@material-ui/core';
import Toolbar from './Toolbar';

import Table, { type DefaultSort, type OrderByProperty } from './Table';

// Component styles
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  }
}));

type Props<T> = {
  noDataMessage: string,
  createFormUrl?: () => Promise<string>,
  addLabelText?: string,
  getData: () => Promise<T[]>,
  filterFn: (T, string) => boolean,
  advancedFilterComponent?: React.Node,
  getId?: T => mixed,
  renderHeaderCells: (
    OrderByProperty<T> | null,
    boolean,
    (OrderByProperty<T>) => void,
    T[]
  ) => React.Node,
  renderDataCells: (T, (T) => void) => React.Node,
  defaultSort?: DefaultSort,
  renderAboveTableDisplay?: ({
    filteredData: T[],
    onRefresh: () => void
  }) => React.Node
};

function TableData<T>({
  createFormUrl,
  addLabelText,
  getData,
  filterFn,
  noDataMessage,
  advancedFilterComponent,
  getId,
  renderHeaderCells,
  renderDataCells,
  defaultSort,
  renderAboveTableDisplay
}: Props<T>) {
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [filter, setFilter] = React.useState('');
  const [error, setError] = React.useState(null);
  const classes = useStyles();

  const handleChange = React.useCallback(
    changed => {
      setData(current => {
        return current.map(d => {
          if (getId && getId(d) === getId(changed)) {
            return changed;
          } else {
            return d;
          }
        });
      });
    },
    [getId]
  );

  React.useEffect(() => {
    // Don't refresh again if already loading
    if (!isLoading) {
      return;
    }

    let ignore = false;

    async function fetchData() {
      try {
        const data = await getData();

        if (!ignore) {
          setError(null);
          setData(data);
          setIsLoading(false);
        }
      } catch (e) {
        if (!ignore) {
          setError(e);
          setIsLoading(false);
        }
      }
    }

    fetchData();
    return () => {
      ignore = true;
    };
  }, [isLoading, getData]);

  const filteredData = React.useMemo(
    () => data.filter(d => filterFn(d, filter)),
    [data, filter, filterFn]
  );
  const onRefresh = React.useCallback(() => setIsLoading(true), []);

  const renderData = () => {
    if (error) {
      return <Typography variant="h6">{error.message}</Typography>;
    }

    return (
      <>
        {!!renderAboveTableDisplay &&
          renderAboveTableDisplay({
            onRefresh,
            filteredData
          })}
        {isLoading ? (
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        ) : !filteredData.length ? (
          <Typography variant="h6">
            {noDataMessage}
            {!advancedFilterComponent && filter
              ? ' that match your filter'
              : ''}
          </Typography>
        ) : (
          <Table
            data={filteredData}
            renderHeaderCells={renderHeaderCells}
            renderDataCells={renderDataCells}
            onChange={handleChange}
            defaultSort={defaultSort}
          />
        )}
      </>
    );
  };

  return (
    <div className={classes.root}>
      <Toolbar
        createFormUrl={createFormUrl}
        filter={filter}
        onChangeFilter={event => setFilter(event.target.value)}
        isLoading={isLoading}
        onRefresh={() => setIsLoading(true)}
        advancedFilterComponent={advancedFilterComponent}
        addLabelText={addLabelText}
      />
      <div className={classes.content}>{renderData()}</div>
    </div>
  );
}

export default TableData;
